import React, { useState } from 'react';
import './Login.css';
import { Link } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
// import { AuthService } from '../_Services/AuthService';
import { addUser } from '../../services/user';

enum UserStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum SignupActivationdSteps {
  SIGN_UP = 'SIGN_UP',
  ENTER_OTP = 'ENTER_OTP',
  ENTER_OTP_ERROR = 'ENTER_OTP_ERROR',
  ENTER_OTP_SUCCESS = 'ENTER_OTP_SUCCESS',
}

function SignUpVerificationError({ doStep, setToken }: { doStep: Function; setToken: Function }) {
  const [message, setMessage] = useState('');
  const [loadSubmit, setLoadSubmit] = useState(false);
  const [status, setStatus] = useState<UserStatus | null>(null);

  type Inputs = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({});

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoadSubmit(true);

    const userData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
      phone: data.phone,
    };

    // eslint-disable-next-line no-shadow
    const { status, message, errors: err, statusCode } = await addUser(userData);

    setLoadSubmit(false);
    setStatus(status);
    setMessage(message);

    console.log(status);
    if (status === UserStatus.SUCCESS) {
      reset();
      doStep(SignupActivationdSteps.ENTER_OTP_SUCCESS);
    } else {
      doStep(SignupActivationdSteps.ENTER_OTP_ERROR);
    }
  };

  return (
    <div className="full_width">
      <div className="password_ripple">
        <img src="/images/forgot-password-ripple-bg.png" alt="" />
      </div>
      <div className="password_blur" />
      <div className="middle_container">
        <div className="form_container password_form_container">
          <form id="contact-form1" className="get-a-quote" autoComplete="off">
            <div className="text-center">
              <div className="heading_content">
                <img src="/images/password-icon.svg" alt="" width="56" height="56" />
                <h2 className="text-gradient">Verification Error</h2>
                <p className="p-0">No worries, we'll send you reset instructions.</p>
              </div>
            </div>
            {/* <div className="login_content">
                    <div className="form-group">
                      <label>Email *</label>
                      <input className="form-control" type="text" placeholder="Enter your email" />
                    </div>
                    <div className="buttons-wrap full_width mt-2">
                      <button type="submit" disabled={loadSubmit}>Reset Password</button>
                    </div>
                </div> */}
            <div className="back_login_link full_width">
              <Link to="/login">
                <img src="/images/arrow-left-icon.svg" alt="" width="20" height="20" />
                Back to Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUpVerificationError;
