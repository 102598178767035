import React, { useState } from 'react';
import './Login.css';
// import { Link } from 'react-router-dom';
import LoginForm from './LoginForm';

// enum UserStatus {
//   SUCCESS = 'success',
//   ERROR = 'error',
// }

export enum LoginActivationdSteps {
  LOGIN = 'LOGIN',
}

export function Login() {
  const [step, setStep] = useState<LoginActivationdSteps | ''>(LoginActivationdSteps.LOGIN);
  const [token, setToken] = useState(null);
  const [email, setEmail] = useState(null);

  return (
    <div className="signup_container">
      {step === LoginActivationdSteps.LOGIN && (
        <LoginForm doStep={setStep} setEmail={setEmail} email={email} setToken={setToken} />
      )}
    </div>
  );
}

export default Login;
