/* eslint-disable import/no-import-module-exports */
//import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import App from './app';
import 'assets/css/main.css';
import 'assets/css/app.css';
import { Provider } from 'react-redux';
import { store } from './store';

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);

serviceWorkerRegistration.unregister();

reportWebVitals();
