import React from 'react';
import './Login.css';
import { Link } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { forgotUserPassword } from '../../services/user';
import { useState } from 'react';
import { AlertMessage } from '../common/Messages';

enum UserStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}
export enum ResetPasswordSteps {
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  ENTER_OTP = 'ENTER_OTP',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
}
function ForgotEmail({
  doStep,
  setEmail,
  setToken,
  parentUserData,
}: {
  doStep: Function;
  setEmail: Function;
  setToken: Function;
  parentUserData: Function;
}) {
  const [message, setMessage] = useState('');
  const [loadSubmit, setLoadSubmit] = useState(false);

  const [status, setStatus] = useState<UserStatus | null>(null);

  type Inputs = {
    email: string;
    password: string;
    confirmpassword: string;
  };

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({});
  // const onSubmit = async data => { console.log(data); };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log(data);
    setLoadSubmit(true);
    const userData = data;

    // const {
    //   status,
    //   message: message,
    //   errors: err,
    //   token: token,
    //   statusCode,
    // } = await forgotUserPassword(userData);
    const response = await forgotUserPassword(userData);

    if (response.success) {
      console.log(response);
      setMessage(response.message);
      setLoadSubmit(false);
      setToken(response.token);
      reset();
      parentUserData(userData);
      doStep(ResetPasswordSteps.ENTER_OTP);
    } else {
      setLoadSubmit(false);
      setStatus(UserStatus.ERROR);
      setMessage(response.message);
      //doStep(SignupActivationdSteps.ENTER_OTP_SUCCESS);
    }
    //console.log(status);
    // setLoadSubmit(false);
    // setStatus(status);
    // setMessage(message);
    // setToken(token);

    // console.log(status);
    // if (status === UserStatus.SUCCESS) {
    //   reset();
    //   doStep(ResetPasswordSteps.ENTER_OTP);
    // }
  };

  return (
    <div className="middle_container forgot_password_container">
      <div className="logo logo-center text-center">
        <Link to="/">
          <img
            src="https://static-web.fra1.digitaloceanspaces.com/royalarion/ra-logo.svg"
            width="281"
            height="59"
            alt=""
          />
        </Link>
      </div>
      <div className="form_container password_form_container">
        <form
          id="contact-form1"
          className="get-a-quote"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="text-center">
            <div className="heading_content">
              <img
                src="https://static-web.fra1.digitaloceanspaces.com/royalarion/password-icon.svg"
                alt=""
                width="56"
                height="56"
              />
              <h2 className="text-gradient">Forgot Password</h2>
              <p className="p-0">No worries, we'll send you reset instructions.</p>
            </div>
          </div>
          {status === UserStatus.SUCCESS && <AlertMessage type="success" message={message} />}
          {status === UserStatus.ERROR && <AlertMessage type="danger" message={message} />}
          <div className="login_content">
            <div className="form-group">
              <label>Email *</label>
              <input
                type="text"
                placeholder="Enter your email"
                id="email"
                name="email"
                {...register('email', {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                })}
                className={`form-control ${errors.email ? 'input_error' : ''}`}
              />
              {errors.email?.type === 'required' && (
                <span className="error-msg">Email is required</span>
              )}
              {errors.email?.type === 'pattern' && (
                <span className="error-msg"> Invalid Email format</span>
              )}
            </div>
            <div className="form-group">
              <label>Password *</label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Enter your Password"
                {...register('password', {
                  required: true,
                  maxLength: 30,
                  minLength: 1,
                  pattern: /^[a-zA-Z0-9_]+.*$/,
                })}
                className={`form-control ${errors.password ? 'input_error' : ''}`}
              />
              {errors.password?.type === 'required' && (
                <span className="error-msg">Password is required</span>
              )}
              {errors.password?.type === 'maxLength' && (
                <span className="error-msg"> Max length exceeded</span>
              )}
              {errors.password?.type === 'pattern' && (
                <span className="error-msg">Invalid password format</span>
              )}
            </div>
            <div className="form-group">
              <label>Confirm Password *</label>
              <input
                type="password"
                name="confirmpassword"
                id="confirmpassword"
                placeholder="Enter your Confirm Password"
                {...register('confirmpassword', {
                  required: true,
                  validate: {
                    matchesPreviousPassword: (value) => {
                      const { password } = getValues();
                      return password === value || 'Passwords should match!';
                    },
                  },
                })}
                className={`form-control ${errors.confirmpassword ? 'input_error' : ''}`}
              />
              {errors.confirmpassword && (
                <span className="error_field">{errors.confirmpassword.message}</span>
              )}
              {errors.confirmpassword?.type === 'required' && (
                <span className="error-msg">Password is required</span>
              )}
              {errors.confirmpassword?.type === 'maxLength' && (
                <span className="error-msg"> Max length exceeded</span>
              )}
              {errors.confirmpassword?.type === 'pattern' && (
                <span className="error-msg">Invalid password format</span>
              )}
            </div>
            <div className="buttons-wrap full_width mt-2">
              <button type="submit" disabled={loadSubmit}>
                {loadSubmit && <span className="spinner-border spinner-border-sm"></span>}
                &nbsp;&nbsp; Reset Password
              </button>
            </div>
          </div>
          <div className="back_login_link full_width">
            <Link to="/login">
              <img
                src="https://static-web.fra1.digitaloceanspaces.com/royalarion/arrow-left-icon.svg"
                alt=""
                width="20"
                height="20"
              />
              Back to Login
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotEmail;
