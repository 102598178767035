import React from 'react';
import { Route, Navigate , RouteProps} from 'react-router-dom';
import { connect } from "react-redux";

function PaymentRoute({ children}) {

    const userinfo = localStorage.getItem("userInfo");
    const userInfoData = JSON.parse(userinfo);
    console.log(userInfoData);
    return (
        <>
        {userinfo && userInfoData.package!='' ? <>{children}</> : <Navigate to="/app/membershipplans" />}
        </>
    );
}


export default PaymentRoute;
//export default PrivateRoute ;