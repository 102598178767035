import React from 'react';

export const extractCountryFromString = (name: any) => {
  const regExp = /\(([^)]+)\)/;
  const excractCode = regExp.exec(name);
  return excractCode?.length ? excractCode[1] : 'ind';
};

export const venueRefactorDataFn = (data: any) =>
  Object.entries(data.racecard).map((venue) => {
    const [venueId, races] = venue;
    const { vName, vCode, id } = races[0];
    const countryCode = extractCountryFromString(vName).toLowerCase();
    const venueCode = vCode.toLowerCase();
    const numberOfRaces = Object.values(races)?.length;
    return {
      id,
      venueName: vName,
      numberOfRaces,
      countryCode,
      venueCode,
      venueId,
    };
  });
export const countryImage = (countryCode, rectImage = false) => {
  const rectType = rectImage ? '-flag-rect' : '-round-flag';
  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      src={`https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/${countryCode}${rectType}.png`}
      width="20"
      height="20"
    />
  );
};
