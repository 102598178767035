/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from 'components/Auth/Header';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function Pricing() {
  
  return (
    <>
      <Header />
      <div className="full_width">
        <div className="pricing_container">
          <div className="full_width">
            <div className="password_ripple_effect" />
            <div className="password_blur" />
            <div className="membership_wrapper full_width">
              <div className="container membership_container">
                <div className="membership_heading full_width">
                  <h4 className="pricing_tp_sp">Membership Plans</h4>
                  <p>Follow the sport of kings & queens by subscribing to our membership today!</p>
                </div>
                {/* start of types of membership container */}
                <div className="types_of_membership_container pricing_types_membership_container full_width">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="types_of_membership_content full_width">
                          <div className="membership_tabs_content full_width">
                            <Tabs defaultActiveKey="digital" id="uncontrolled-tab-example">
                              <Tab eventKey="digital" title="DIGITAL">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <div className="member_tab_left_content full_width">
                                        <h5>DIGITAL<br/>MEMBERSHIP</h5>
                                        <p>
                                          <strong>£20</strong>
                                          <small>/Month</small>
                                          <strong>£216</strong>
                                          <small>/Year</small>
                                          <span>10% off</span>
                                        </p>
                                        <p className="no_br_tp">
                                          <strong>₹2100</strong>
                                          <small>/Month</small>
                                          <strong>₹22,500</strong>
                                          <small>/Year</small>
                                          <span>10% off</span>
                                        </p>
                                        <Link to="/signup">Sign up</Link>
                                    </div>
                                  </div>
                                  <div className="col-lg-9">
                                    <div className="member_tab_right_content full_width">
                                      <h6>MEMBERSHIP BENEFITS</h6>
                                      <ul>
                                        <li>Own a 0.1% <b>club share</b> in one specified horse in India or UK, as chosen by the member.</li>
                                        <li>Share of the prize money won by the specified horse.</li>
                                        <li>Follow all Royal Arion Club horses through our App.</li>
                                        <li>Watch live international racing in app (for members based in India)</li>
                                        <li>Advanced racing data, including race cards, trainer, jockey, vet, and industry professional reports for the specified horse.</li>
                                        <li>Attend races when your horse runs.</li>
                                        <li>Visits to see the specified horse in training and meet the trainers on pre-determined dates throughout the year.</li>
                                        <li>Enter the Club’s regular raffles, and win extraordinary racing and luxury experiences</li>
                                      </ul>
                                      <div className="fractional_ownership">
                                        <h4>OWN A SHARE IN ONE OF THE FOLLOWING </h4>
                                      </div>
                                      <div className="row stable_list">
                                        <div className="col-6">
                                          <div className="card_container">
                                            <div className="img_wrap">
                                              <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/fascinating-rock-thumb-img.jpg" alt="" width={180} height={180} />
                                            </div>
                                            <div className="stable_card_content">
                                              <img src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/uk-round-flag.svg"
                                                alt="" width={17} height={17} />
                                              <h5>Rock and Royal</h5>
                                              <div className="horse_details_info full_width">
                                                <label>Sire / Dam</label>
                                                <p>Fascinating Rock (IRE) / Spesialta (GB)</p>
                                              </div>
                                              <div className="horse_details_info horse_desc_info mb-0">
                                                <label>Desc.</label>
                                                <p>3yo Bay Filly</p>
                                              </div>
                                              <div className="horse_details_info trainer_detl_info mb-0">
                                                <label>Trainer</label>
                                                <p>Dylan Cunha</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          <div className="card_container">
                                            <div className="img_wrap">
                                              <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/chinese-whisper-royal-thumb-img.jpg" alt="" width={180} height={180} />
                                            </div>
                                            <div className="stable_card_content">
                                              <img src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-round-flag.svg"
                                                alt="" width={17} height={17} />
                                              <h5>Royal Whisper</h5>
                                              <div className="horse_details_info full_width">
                                                <label>Sire / Dam</label>
                                                <p>Chinese Whisper / Royal Superlative</p>
                                              </div>
                                              <div className="horse_details_info horse_desc_info mb-0">
                                                <label>Desc.</label>
                                                <p>3yo Bay Filly</p>
                                              </div>
                                              <div className="horse_details_info trainer_detl_info mb-0">
                                                <label>Trainer</label>
                                                <p>Neil Devaney</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          <div className="card_container">
                                            <div className="img_wrap">
                                              <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/eloquent-thumb-img.jpg" alt="" width={180} height={180} />
                                            </div>
                                            <div className="stable_card_content">
                                              <img src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-round-flag.svg"
                                                alt="" width={17} height={17} />
                                              <h5>Eloquent</h5>
                                              <div className="horse_details_info full_width">
                                                <label>Sire / Dam</label>
                                                <p>Speaking of Which / Auntie Kathryn</p>
                                              </div>
                                              <div className="horse_details_info horse_desc_info mb-0">
                                                <label>Desc.</label>
                                                <p>4yo Bay Gelding</p>
                                              </div>
                                              <div className="horse_details_info trainer_detl_info mb-0">
                                                <label>Trainer</label>
                                                <p>Shazaan Shah</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tab>
                              <Tab eventKey="club" title="CLUB">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <div className="member_tab_left_content full_width">
                                        <h5>CLUB<br/>MEMBERSHIP</h5>
                                        <p>
                                          <strong>£2500</strong>
                                          <small>/Year</small>
                                        </p>
                                        <p className='no_br_tp'>
                                          <strong>₹255,000</strong>
                                          <small>/Year</small>
                                        </p>
                                        <Link to="/signup">Sign up</Link>
                                    </div>
                                  </div>
                                  <div className="col-lg-9">
                                    <div className="member_tab_right_content full_width">
                                      <h6>MEMBERSHIP BENEFITS</h6>
                                      <ul>
                                        <li>Own club shares in all Club horses in training (currently 2 in the UK &amp; 4 in India) whilst being treated as they are your very own. Follow all 8 Royal Arion horses via our interactive app.</li>
                                        <li>Share of prize money won by the Club horses.</li>
                                        <li>Attend the races with owners and trainers access. Stable and gallops visits to see horses and meet the trainers.</li>
                                        <li>Racing industry editorial.</li>
                                        <li>Watch live international racing in app (for members based in India)</li>
                                        <li>Advanced racing data, including race cards, trainer, jockey, vet, and industry professional reports for the specified horse.</li>
                                        <li>Access to all Club services and privileges, including social &amp; networking events</li>
                                        <li>Enjoy benefits, offers, editorial and entertainment from our Luxury Brand partners.</li>
                                        <li>Invitations to exclusive events in the UK, India, and beyond.</li>
                                        <li>Enter the Club’s regular raffles, and win extraordinary global elite racing and luxury experiences</li>
                                      </ul>
                                      <div className="fractional_ownership">
                                        <h4>OWN CLUB SHARES IN ALL OF THE FOLLOWING:</h4>
                                      </div>
                                      <div className="row stable_list">
                                        <div className="col-6">
                                          <div className="card_container">
                                            <div className="img_wrap">
                                              <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/sergei-thumb-img.jpg" alt="" width={180} height={180} />
                                            </div>
                                            <div className="stable_card_content">
                                              <img src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/uk-round-flag.svg"
                                                alt="" width={17} height={17} />
                                              <h5>Sergei Prokofiev x Charline Royale</h5>
                                              <div className="horse_details_info full_width">
                                                <label>Sire / Dam</label>
                                                <p>Sergei Prokofiev /Charline Royale (Ire)</p>
                                              </div>
                                              <div className="horse_details_info horse_desc_info mb-0">
                                                <label>Desc.</label>
                                                <p>2yo Bay Filly</p>
                                              </div>
                                              <div className="horse_details_info trainer_detl_info mb-0">
                                                <label>Trainer</label>
                                                <p>Andrew Balding</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          <div className="card_container">
                                            <div className="img_wrap">
                                              <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/coeur-de-lion-thumb-img.jpg" alt="" width={180} height={180} />
                                            </div>
                                            <div className="stable_card_content">
                                              <img src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-round-flag.svg"
                                                alt="" width={17} height={17} />
                                              <h5>Coeur De Lion</h5>
                                              <div className="horse_details_info full_width">
                                                <label>Sire / Dam</label>
                                                <p>Roderic O'Connor[IRE] / Sweeping Star</p>
                                              </div>
                                              <div className="horse_details_info horse_desc_info mb-0">
                                                <label>Desc.</label>
                                                <p>5yo Bay Colt</p>
                                              </div>
                                              <div className="horse_details_info trainer_detl_info mb-0">
                                                <label>Trainer</label>
                                                <p>Narendra Lagad</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row stable_list">
                                        <div className="col-6">
                                          <div className="card_container">
                                            <div className="img_wrap">
                                              <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/leitir-thumb-img.jpg" alt="" width={180} height={180} />
                                            </div>
                                            <div className="stable_card_content">
                                              <img src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-round-flag.svg"
                                                alt="" width={17} height={17} />
                                              <h5>Leitir Mor x Stravella</h5>
                                              <div className="horse_details_info full_width">
                                                <label>Sire / Dam</label>
                                                <p>Stravella (IRE) / Leitir Mor</p>
                                              </div>
                                              <div className="horse_details_info horse_desc_info mb-0">
                                                <label>Desc.</label>
                                                <p>3yo Bay Filly</p>
                                              </div>
                                              <div className="horse_details_info trainer_detl_info mb-0">
                                                <label>Trainer</label>
                                                <p>Hilton Sequera</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          <div className="card_container">
                                            <div className="img_wrap">
                                              <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/showcasing-thumb-img.jpg" alt="" width={180} height={180} />
                                            </div>
                                            <div className="stable_card_content">
                                              <img src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/uk-round-flag.svg"
                                                alt="" width={17} height={17} />
                                              <h5>Royal Elysian</h5>
                                              <div className="horse_details_info full_width">
                                                <label>Sire / Dam</label>
                                                <p>Showcasing (GB) / Chloris (GB)</p>
                                              </div>
                                              <div className="horse_details_info horse_desc_info mb-0">
                                                <label>Desc.</label>
                                                <p>3yo Bay Filly</p>
                                              </div>
                                              <div className="horse_details_info trainer_detl_info mb-0">
                                                <label>Trainer</label>
                                                <p>James Ferguson</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row stable_list">
                                        <div className="col-6">
                                          <div className="card_container">
                                            <div className="img_wrap">
                                              <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/gusto-thumb-img.jpg" alt="" width={180} height={180} />
                                            </div>
                                            <div className="stable_card_content">
                                              <img src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-round-flag.svg"
                                                alt="" width={17} height={17} />
                                              <h5>Royal Decan</h5>
                                              <div className="horse_details_info full_width">
                                                <label>Sire / Dam</label>
                                                <p>Gusto (GB) / Fearless Nadia</p>
                                              </div>
                                              <div className="horse_details_info horse_desc_info mb-0">
                                                <label>Desc.</label>
                                                <p>3yo Bay Colt</p>
                                              </div>
                                              <div className="horse_details_info trainer_detl_info mb-0">
                                                <label>Trainer</label>
                                                <p>Arjun Mangalorkar</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          <div className="card_container">
                                            <div className="img_wrap">
                                              <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/chinese-whisper-charm-thumb-img.jpg" alt="" width={180} height={180} />
                                            </div>
                                            <div className="stable_card_content">
                                              <img src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-round-flag.svg"
                                                alt="" width={17} height={17} />
                                              <h5>Emperors Charm</h5>
                                              <div className="horse_details_info full_width">
                                                <label>Sire / Dam</label>
                                                <p>Chinese Whisper(IRE) / So Charm (IRE)</p>
                                              </div>
                                              <div className="horse_details_info horse_desc_info mb-0">
                                                <label>Desc.</label>
                                                <p>3yo Bay Colt</p>
                                              </div>
                                              <div className="horse_details_info trainer_detl_info mb-0">
                                                <label>Trainer</label>
                                                <p>Hilton Sequera</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tab>
                            </Tabs>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end of types of membership container */}
                <div className="membership_content full_width">
                  <div className="membership_plan_wrapper full_width">
                    {/* start of membership plans desktop */}
                    <div className="row membership_plans_desktop">
                      <div className="col-lg-6 pr-0">
                        <div className="plan_left_content full_width">
                          <div className="plan_price">
                            <h5>ANNUAL PRICE</h5>
                            <h3>Features</h3>
                          </div>
                          <div className="plan_features">
                            <p>Own a share in 6 racehorses internationally</p>
                            <p>Own a 0.1% share in 1 racehorse</p>
                            <p className="text_nowrap">Watch live international racing in app (for members based in India)</p>
                            <p>Unique App Access</p>
                            <p>Trainer, Jockey, Vet, Industry professional reports</p>
                            <p>Acces to trainer, stables, horse and gallops visit</p>
                            <p>Attend exclusive luxury brand partner events</p>
                            <p>Attend exclusive  Royal Arion social & Networking and Events</p>
                            <p>Racing Industry Editorial</p>
                            <p>Luxury Brand Partner Editorial</p>
                            <p>Advanced Racing Data</p>
                            <p>Luxury Brand Partner offers and discounts</p>
                            <p>Founders Card membership</p>
                            <p>Racing manager weekly updates</p>
                            <p>Events calendar</p>
                            <p>Racing concierge</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 pl-0 pr-0">
                        <div className="plan_price_info plan_regular_info full_width">
                          <div className="plan_info">
                            <h6>DIGITAL</h6>
                            <div className='plan_info_list full_width'>
                              <h4 className='digital_monthly'>
                                £20<small>/ Month</small>
                              </h4>
                              <strong>/</strong>
                              <h4 className='digital_yearly'>
                                £216<small>/ Year</small>
                              </h4>
                            </div>
                            <div className='plan_info_list full_width no_tp_br'>
                              <h4 className='digital_monthly'>
                                ₹2100<small>/ Month</small>
                              </h4>
                              <strong>/</strong>
                              <h4 className='digital_yearly'>
                                ₹22,500<small>/ Year</small>
                              </h4>
                            </div>
                            <Link to="/signup">
                              <button type="button"> Get Started</button>
                            </Link>
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-no.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <strong>Limited</strong>
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-no.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-no.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-no.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-no.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-no.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-no.svg"
                              alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 pl-0">
                        <div className="plan_price_info plan_platinum_info full_width">
                          <div className="plan_info">
                            <h6>CLUB</h6>
                            <div className='plan_info_list full_width'>
                              <h4>
                                £2500<small>/ Year</small>
                              </h4>
                            </div>
                            <div className='plan_info_list full_width no_tp_br'>
                              <h4>
                                ₹255,000<small>/ Year</small>
                              </h4>
                            </div>
                            <Link to="/signup">
                              <button type="button"> Get Started</button>
                            </Link>
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="plan_access">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end of membership plans desktop */}
                    {/* start of membership plans mobile */}
                    <div className="membership_plans_mobile full_width">
                    <div className="membership_card_wrapper">
                        <div className="membership_card_heading">
                          <h6>DIGITAL</h6>
                          <div className="plan_info_list full_width">
                            <h4 className='digital_monthly'>
                              £20<small>/ Month</small>
                            </h4>
                            <strong>/</strong>
                            <h4 className='digital_yearly'>
                              £216<small>/ Year</small>
                            </h4>
                          </div>
                          <div className="plan_info_list full_width no_tp_br">
                            <h4 className='digital_monthly'>
                              ₹2100<small>/ Month</small>
                            </h4>
                            <strong>/</strong>
                            <h4 className='digital_yearly'>
                              ₹22,500<small>/ Year</small>
                            </h4>
                          </div>
                          <Link to="/signup">
                            <button type="button">Get Started</button>
                          </Link>
                        </div>
                        <div className="membership_card_content">
                          <div className="membership_card_list">
                            <p>Own a share in 6 racehorses internationally</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-no.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Own a 0.1% share in 1 racehorse</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Watch live international racing in app (for members based in India)</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Unique App Access</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Trainer, Jockey, Vet, Industry professional reports</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Acces to trainer, stables, horse and gallops visit</p>
                            <strong>Limited</strong>
                          </div>
                          <div className="membership_card_list">
                            <p>Attend exclusive luxury brand partner events</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-no.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Attend exclusive  Royal Arion social & Networking and Events</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-no.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Racing Industry Editorial</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Luxury Brand Partner Editorial</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Advanced Racing Data</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Luxury Brand Partner offers and discounts</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-no.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Founders Card membership</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-no.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Racing manager weekly updates</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-no.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Events calendar</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Racing concierge</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-no.svg"
                              alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="membership_card_wrapper">
                        <div className="membership_card_heading">
                          <h6>CLUB</h6>
                          <div className="plan_info_list full_width">
                            <h4>
                              £2500<small>/ Year</small>
                            </h4>
                          </div>
                          <div className="plan_info_list full_width no_tp_br">
                            <h4>
                              ₹255,000<small>/ Year</small>
                            </h4>
                          </div>
                          <Link to="/signup">
                            <button type="button">Get Started</button>
                          </Link>
                        </div>
                        <div className="membership_card_content">
                          <div className="membership_card_list">
                            <p>Own a share in 6 racehorses internationally</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Own a 0.1% share in 1 racehorse</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Watch live international racing in app (for members based in India)</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Unique App Access</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Trainer, Jockey, Vet, Industry professional reports</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Acces to trainer, stables, horse and gallops visit</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Attend exclusive luxury brand partner events</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Attend exclusive  Royal Arion social & Networking and Events</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Racing Industry Editorial</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Luxury Brand Partner Editorial</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Advanced Racing Data</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Luxury Brand Partner offers and discounts</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Founders Card membership</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Racing manager weekly updates</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Events calendar</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                          <div className="membership_card_list">
                            <p>Racing concierge</p>
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/plan-yes.svg"
                              alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end of membership plans mobile */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pricing;
