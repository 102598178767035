import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
// import { logout } from '../Auth/logout';
// import { getuserProfile } from '../../services/user';
import { AlertMessage } from 'components/common/Messages';
import { loadStripe } from '@stripe/stripe-js';
import { paymentStatus } from '../../services/payment';
import LocalStorageService from '../_Services/LocalStorageService';

enum UserStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

function Cancel() {
  const location = useLocation();
  const sessionId = location.search.replace('?session_id=', '');

  const [loadSubmit, setLoadSubmit] = useState(false);
  const [user, setUser] = useState({ full_name: '', user_email: '', user_mobile: '' });
  // const getProfile = async () => {
  //   const data = await getuserProfile();
  //   setUser(data.data);
  // };
  const getPaymentStatus = async () => {
    const paymentData = { session: sessionId };
    const response = await paymentStatus(paymentData);
    console.log(response.session);
    const userinfo = await LocalStorageService.getItem('userInfo');
    const updateuserInfo = JSON.parse(userinfo);
    updateuserInfo.package = response.session.package;
    console.log(updateuserInfo);
    localStorage.setItem('userInfo', JSON.stringify(updateuserInfo));
  };

  useEffect(() => {
    getPaymentStatus();
  }, []);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.setItem('access_token', '');
    navigate('/');
  };
  return (
    <section className="signup_container">
      <div className="full_width">
        <div className="password_ripple_effect" />
        <div className="password_blur" />
        <div className="success_message_wrapper">
          <div className="success_message_container">
            <div className="img_container">
              <img
                src="https://static-web.fra1.digitaloceanspaces.com/royalarion/success-banner-img.jpg"
                alt=""
                width={600}
                height={384}
              />
            </div>
            <div className="success_content">
              <h5>Payment Failed!</h5>
              <p>Your transaction has failed due to some technical error. Please try again</p>
              <div className="home_link">
                <Link to="/app/membershipplans">Make Payment</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cancel;
