import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
      <div className='footer_container'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-7'>
                <div className='gateway_info'>
                  <h3>Your gateway to an exclusive racing lifestyle</h3>
                  <Link to="/signup">Sign up</Link>
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='footer_links'>
                  <h6>Get in touch with us</h6>
                  <a href='mailto:membersupport@royalarion.com'>
                    <img src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/footer-mail-line-icon.svg" alt='' />
                    <span>membersupport@royalarion.com</span>
                  </a>
                  <ul className='social_links'>
                    <li>
                      <Link to="https://www.instagram.com/royalarion/" target='_blank'>
                        <img src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/footer-insta-icon.svg" alt='' />
                      </Link>
                    </li>
                    <li>
                      <Link to="/" className="nolink">
                        <img src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/footer-twitter-icon.svg" alt='' />
                      </Link>
                    </li>
                    <li>
                      <Link to="/" className="nolink">
                        <img src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/footer-facebook-icon.svg" alt='' />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-2'>
                <div className='footer_links footer_links_bottom'>
                  {/* <h6 className='company-text'>Company</h6> */}
                  <ul className='innerpages_links'>
                    <li>
                      <Link to="/termsandconditions">
                        Terms & Conditions
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/">
                        Cookies
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        Help
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        Privacy
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='footer_bottom_container full_width'>
                    <span>&copy; 2023 The Royal Arion Club.</span>
                    <Link to="/">
                      <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/ra-logo.svg" alt='' />
                    </Link>
                </div>
              </div>
            </div>
          </div>
      </div>
  );
}

export default Footer;
