import React, { useState } from 'react';
import './Login.css';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { signupVerification, resendVerificationEmail } from '../../services/user';
import { AlertMessage } from '../common/Messages';

enum UserStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum SignupActivationdSteps {
  SIGN_UP = 'SIGN_UP',
  ENTER_OTP = 'ENTER_OTP',
  ENTER_OTP_ERROR = 'ENTER_OTP_ERROR',
  ENTER_OTP_SUCCESS = 'ENTER_OTP_SUCCESS',
}

function SignUpVerification({
  doStep,
  userData,
  token,
  redirect,
}: {
  doStep: Function;
  token: string;
  userData: any;
  redirect?: boolean;
}) {
  const [email, setEmail] = useState(userData.email);
  const [message, setMessage] = useState('');
  const [loadSubmit, setLoadSubmit] = useState(false);
  const [status, setStatus] = useState<UserStatus | null>(null);

  type Inputs = {
    token: string;
    otpToken: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
  };
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({});

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoadSubmit(true);
    userData.emailOtp = data.otpToken;
    // const userData = {
    //   token,
    //   otpToken: data.otpToken,
    //   firstName: data.firstName,
    //   lastName: data.lastName,
    //   email: data.email,
    //   password: data.password,
    //   phone: data.phone,
    // };
    // const { errors: err, statusCode } = await signupVerification(userData);
    const response = await signupVerification(userData);

    if (response.error) {
      setMessage(response.message);
      setLoadSubmit(false);
      setStatus(UserStatus.ERROR);
      doStep(SignupActivationdSteps.ENTER_OTP);
    } else {
      doStep(SignupActivationdSteps.ENTER_OTP_SUCCESS);
    }
  };
  const reSendVerification = async () => {
    reset();

    const response = await resendVerificationEmail(userData);
    if (response.error) {
      setMessage(response.message);
      setLoadSubmit(false);
      setStatus(UserStatus.ERROR);
      doStep(SignupActivationdSteps.ENTER_OTP);
    } else {
      console.log(response.body);
      userData.token = response.body.token;
      setMessage(response.body.message);
      setLoadSubmit(false);
      setStatus(UserStatus.SUCCESS);
      //doStep(SignupActivationdSteps.ENTER_OTP);
    }
    // setLoadSubmit(false);
    // setStatus(status);
    // setMessage(error);
  };
  function getLogin(): void {
    throw new Error('Function not implemented.');
  }
  // const handleVerification = (object) => {
  //   console.log(object);
  //   if (object.target.value.length > object.target.maxLength) {
  //     object.target.value = object.target.value.slice(
  //       object.target.minLength,
  //       object.target.maxLength,
  //     );
  //     //this.setState({ phone: object.target.value });
  //   }
  // };

  return (
    <div className="full_width">
      <div className="password_ripple">
        <img src="/images/forgot-password-ripple-bg.png" alt="" />
      </div>
      <div className="password_blur" />
      <div className="middle_container">
        <div className="form_container password_form_container">
          <form
            id="contact-form1"
            className="get-a-quote"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="text-center">
              <div className="heading_content">
                <img src="/images/mail-line-icon.svg" alt="" width="56" height="56" />
                <h2 className="text-gradient">Check your email</h2>
                <p className="p-0">We sent a verification pin to {email}</p>
              </div>
            </div>
            {status === UserStatus.SUCCESS && <AlertMessage type="success" message={message} />}
            {status === UserStatus.ERROR && <AlertMessage type="danger" message={message} />}
            <div className="login_content">
              <div className="form-group">
                <label>Verification Code *</label>
                <input
                  type="text"
                  placeholder="Enter your Verification Code"
                  name="otpToken"
                  maxLength={4}
                  id="otpToken"
                  {...register('otpToken', { required: true, maxLength: 4, minLength: 4 })}
                  className={`form-control ${errors.otpToken ? 'input_error' : ''}`}
                />
                {errors.otpToken?.type === 'required' && (
                  <span className="error-msg">This field is required</span>
                )}
                {errors.otpToken?.type === 'maxLength' && (
                  <span className="error-msg"> Max length exceeded</span>
                )}
              </div>
              <div className="buttons-wrap full_width mt-2">
                <button type="submit" disabled={loadSubmit}>
                  {loadSubmit && <span className="spinner-border spinner-border-sm" />}
                  &nbsp;&nbsp; Verify email
                </button>
              </div>
            </div>
            <div className="allready_account full_width text-center pt-4">
              <p>
                Didn't receive the email?
                <Link to="#" onClick={() => reSendVerification()}>
                  Click to resend
                </Link>
                {/* <a onClick={reSendVerification}>Click to resend</a> */}
              </p>
            </div>
            <div className="back_login_link full_width">
              <Link to="/login" onClick={() => getLogin()}>
                <img src="/images/arrow-left-icon.svg" alt="" width="20" height="20" />
                Back to Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUpVerification;
