import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import Header from 'components/Auth/Header';
import Footer from 'components/Auth/Footer';

function LoginLayout() {
  return (
    <>
      <div className='wrapper'>
        
        <Outlet />
        <Footer />
      </div>
    </>
  );
}
export default LoginLayout;
