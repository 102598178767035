import Header from 'components/Auth/Header';
import React from 'react';
import { Link } from 'react-router-dom';

function About() {
  return (
    <><Header /><div className="content_container about_content_container">
          <div className="about_container">
              <div className='about_top_ripple'>
                  <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/about-ripple-top.png" alt='' />
              </div>
              <div className='about_top_blur'>
              </div>
              {/* <div className='about_gradient_img'>
      <img src="/images/about-gradient-img.gif" alt='' />
    </div> */}
              <div className="container">
                  <div className="row">
                      <div className="col-12">
                          <div className="about_header text-center">
                              <h2 className='text-gradient'>The Royal Arion Club Welcomes You</h2>
                              <p>To follow this sport of Kings and Queens. To be entertained, educated and inspired.</p>
                          </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-12">
                          <div className="about_video_container">
                              <video controls loop playsInline poster='https://static-web.fra1.digitaloceanspaces.com/royalarion/about-video-img.png'>
                                  <source src="https://static-web.fra1.digitaloceanspaces.com/royalarion/about-video.mp4" type="video/mp4" />
                              </video>
                          </div>
                      </div>
                  </div>
                  <div className="row about_content">
                      <div className="about_left_content col-lg-6 col-md-6 col-12">
                          <h5>About us</h5>
                          <h4 className='text-gradient'>The Best or Nothing at All</h4>
                      </div>
                      <div className="about_right_content col-lg-6 col-md-6 col-12">
                          <p>The Royal Arion Club is a global private members club centred around refined racehorse ownership. We offer our members access to an unparalleled lifestyle, complete with the finest experiences. Refined racehorse ownership, brand partner editorial content, membership privileges and exclusive curated experiences through our luxury partners.</p>
                          {/* <p className={state.countryName === 'Saudi Arabia' ? 'about_text_info d-block' : 'about_text_info d-none'}>The Royal Arion Club is a global private members digital sports club. We offer our members access to an unparalleled lifestyle, complete with the finest experiences. Refined racehorse ownership, luxurious editorial content, membership privileges, concierge services, bespoke gifts, and exclusive curated experiences.</p> */}
                          <p>Founded by Hugh O’Donnell, Suresh Paladugu and James O’Donnell, the leadership team bring extensive experience in horse racing, enterprise technology, e-commerce, and the wider equestrian industry. The Royal Arion Club is a global private members club, with the founding team and stakeholders operating in the UK, India, Kingdom of Saudi Arabia, UAE, USA and the Philippines.</p>
                          {/* <p className={state.countryName === 'Saudi Arabia' ? 'd-block' : 'd-none'}>Founded by Hugh O'Donnell, Suresh Paladugu, James O'Donnell, and managing director Dr. Swetha Reddy, the leadership team bring extensive experience in horse racing, enterprise technology, e-commerce, and the wider equestrian industry. The Royal Arion Club is  a global private members club, with the founding team and stakeholders operating in the UK, Kingdom of Saudi Arabia, India,  UAE, USA and the Philippines.</p> */}
                      </div>
                  </div>
                  <div className="row team_content">
                      <div className="col-12">
                          <h3 className='text-gradient'>Meet the Team</h3>
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                  <div className="team_list">
                                      <div className="team_img">
                                          <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/team-img-4.jpg"
                                              alt="" width="596" height="443" />
                                      </div>
                                      <div className="team_info">
                                          <div className="float-left">
                                              <h5>James O'Donnell</h5>
                                              <h6>Director of racing, bloodstock and CEO</h6>
                                          </div>
                                          <div className="team_social_links float-right">
                                              <Link to="/">
                                                  <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/linkedin-icon.svg"
                                                      alt="" width="24" height="24" />
                                              </Link>
                                          </div>
                                          <div className="full_width">
                                              <p>James has worked in the global horse racing and breeding industry for over 20 years. Previously James was the Stud Director at Shadwell Stud. Prior to that he ran the stallion career of the champion Frankel at Juddmonte Farms. He has also worked at the Queens Royal Studs at Sandringham, Swettenham and Arrowfield in Australia.</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-12">
                                  <div className="team_list">
                                      <div className="team_img">
                                          <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/team-img-2.jpg"
                                              alt="" width="596" height="443" />
                                      </div>
                                      <div className="team_info">
                                          <div className="float-left">
                                              <h5>Suresh Paladugu</h5>
                                              <h6>Co-founder & MD</h6>
                                          </div>
                                          <div className="team_social_links float-right">
                                              <Link to="https://www.linkedin.com/in/paladugus/" target="_blank">
                                                  <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/linkedin-icon.svg"
                                                      alt="" width="24" height="24" />
                                              </Link>
                                              <Link to="https://www.northalley.com/" target="_blank">
                                                  <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/internet-icon.svg"
                                                      alt="" width="24" height="24" />
                                              </Link>
                                          </div>
                                          <div className="full_width">
                                              <p>Suresh is the CEO and Founder of NorthAlley India Pvt. Ltd. NorthAlley is a full-stack development house specialising in custom software services.</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-12">
                                  <div className="team_list">
                                      <div className="team_img">
                                          <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/team-img-1.jpg"
                                              alt="" width="596" height="443" />
                                      </div>
                                      <div className="team_info">
                                          <div className="float-left">
                                              <h5>Hugh O'Donnell</h5>
                                              <h6>Founder</h6>
                                          </div>
                                          <div className="team_social_links float-right">
                                              <Link to="/">
                                                  <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/linkedin-icon.svg"
                                                      alt="" width="24" height="24" />
                                              </Link>
                                          </div>
                                          <div className="full_width">
                                              <p>Hugh has a background in horse racing, corporate finance, family office advisory & philanthropic fundraising. Hugh is the founder of Recehorselotto.com and the blockchain solution business Fortisnet Ltd. Previously he worked for Cambridge University Health Partners and was a senior advisor to private family offices in the Middle East.</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-12">
                                  <div className="team_list">
                                      <div className="team_img">
                                          <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/team-img-5.jpg"
                                              alt="" width="596" height="443" />
                                      </div>
                                      <div className="team_info">
                                          <div className="float-left">
                                              <h5>Gaurav Rampal</h5>
                                              <h6>Indian racing consultant & Bloodstock Consultant</h6>
                                          </div>
                                          <div className="team_social_links float-right">
                                              <Link to="/">
                                                  <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/linkedin-icon.svg"
                                                      alt="" width="24" height="24" />
                                              </Link>
                                              <Link to="/">
                                                  <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/twitter-icon.svg"
                                                      alt="" width="24" height="24" />
                                              </Link>
                                              <Link to="/">
                                                  <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/internet-icon.svg"
                                                      alt="" width="24" height="24" />
                                              </Link>
                                          </div>
                                          <div className="full_width">
                                              <p>Gaurav is a leading bloodstock professional with 20 years of experience. He trades, manages and auctions thoroughbreds. He is the only Indian ever to win The Kentucky Derby via micro ownership of 2020 Kentucky Derby winner Authentic.</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-12">
                                  <div className="team_list">
                                      <div className="team_img">
                                         
                                      </div>
                                      <div className="team_info">
                                          <div className="float-left">
                                              <h5>Surya Narreddi</h5>
                                              <h6>Product Consultant</h6>
                                          </div>
                                          <div className="team_social_links float-right">
                                              <Link to="/">
                                                  <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/linkedin-icon.svg"
                                                      alt="" width="24" height="24" />
                                              </Link>
                                              <Link to="/">
                                                  <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/twitter-icon.svg"
                                                      alt="" width="24" height="24" />
                                              </Link>
                                              <Link to="/">
                                                  <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/internet-icon.svg"
                                                      alt="" width="24" height="24" />
                                              </Link>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-12">
                                  <div className="team_list">
                                      <div className="team_img">
                                          
                                      </div>
                                      <div className="team_info">
                                          <div className="float-left">
                                              <h5>Gavin Lenthall of SRLV</h5>
                                              <h6>Accounts and Tax Consultant</h6>
                                          </div>
                                          <div className="team_social_links float-right">
                                              <Link to="/">
                                                  <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/linkedin-icon.svg"
                                                      alt="" width="24" height="24" />
                                              </Link>
                                              <Link to="/">
                                                  <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/twitter-icon.svg"
                                                      alt="" width="24" height="24" />
                                              </Link>
                                              <Link to="/">
                                                  <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/internet-icon.svg"
                                                      alt="" width="24" height="24" />
                                              </Link>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className='about_bottom_ripple'>
                  <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/about-ripple-bottom.png" alt='' />
              </div>
              <div className='about_bottom_blur'></div>
          </div>
      </div></>
  );
}

export default About;
