import React, { useEffect, useState } from 'react';
import './Login.css';
import { Link, useNavigate } from 'react-router-dom';
import { EyeHideIconSvg, EyeIconSvg } from '../App/SvgIcons';
import { useForm, SubmitHandler } from 'react-hook-form';
import { addUser } from '../../services/user';
import { AlertMessage } from '../common/Messages';
import LocalStorageService from 'components/_Services/LocalStorageService';
import MyCarousel from './Landingcardcarousel';
import { ArrowLeftIconSvg } from '../App/SvgIcons';

enum UserStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum SignupActivationdSteps {
  SIGN_UP = 'SIGN_UP',
  ENTER_OTP = 'ENTER_OTP',
  ENTER_OTP_ERROR = 'ENTER_OTP_ERROR',
  ENTER_OTP_SUCCESS = 'ENTER_OTP_SUCCESS',
}

function SignUpForm({
  doStep,
  setEmail,
  setToken,
  parentUserData,
}: {
  doStep: Function;
  setEmail: Function;
  setToken: Function;
  parentUserData: Function;
}) {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [loadSubmit, setLoadSubmit] = useState(false);
  const [status, setStatus] = useState<UserStatus | null>(null);
  const [passwordType, setPasswordType] = useState('password');
  const [cpasswordType, setCpasswordType] = useState('password');
  const [deviceIsMobile, setDeviceIsMobile] = useState(false);

  type Inputs = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
    countrycode: string;
    token: string;
    confirmpassword: string;
  };

  const showPassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };

  const showCPassword = () => {
    if (cpasswordType === 'password') {
      setCpasswordType('text');
      return;
    }
    setCpasswordType('password');
  };

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<Inputs>({});

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoadSubmit(true);
    console.log(data);
    data.phone = data.countrycode + data.phone;
    const userData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
      phone: data.phone,
      token: '',
    };

    // const {
    //   status,
    //   message: message,
    //   errors: err,
    //   token: token,
    //   email: email,
    //   statusCode,
    // } = await addUser(userData);
    const response = await addUser(userData);
    console.log(response);
    if (response.error) {
      setMessage(response.message);
      setLoadSubmit(false);
      setToken(response.token);
      setStatus(UserStatus.ERROR);
      // doStep(SignupActivationdSteps.ENTER_OTP);
    } else {
      parentUserData(userData);
      userData.token = response.token;
      reset();
      doStep(SignupActivationdSteps.ENTER_OTP);
    }
    // setLoadSubmit(false);
    // setStatus(status);
    // setMessage(message);
    // setToken(token);
    // setEmail(userData.email);
    // parentUserData(userData);

    // if (token != null) {
    //   userData.token = token;
    //   reset();
    //   doStep(SignupActivationdSteps.ENTER_OTP);
    // }
  };

  const checkLogin = async () => {
    if ((await LocalStorageService.getItem('userInfo')) === '') return;
    const userinfo = JSON.parse(await LocalStorageService.getItem('userInfo'));
    const currenturl = await LocalStorageService.getItem('current_url');
    if (userinfo != undefined && userinfo.token) {
      if (userinfo.package !== '') {
        navigate('/app/home');
      } else {
        navigate('/app/membershipplans');
      }
    }
  };

  const checkIsMobile = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent,
      )
    ) {
      setDeviceIsMobile(true);
    } else {
      setDeviceIsMobile(false);
    }
  };

  useEffect(() => {
    checkLogin();
    checkIsMobile();
  }, []);

  return (
    <div className="signupformview">
      {/* <div className='login_ripple'>
        <img src="/images/login-ripple-bg.png" alt='' />
      </div> */}
      <div className="login_ripple_effect" />
      <div className="login_blur" />
      <div className="signup_left_container login_left_wrapper">
        <div className="logo text-center">
          <Link to="/" className="back_link_arrow">
            <ArrowLeftIconSvg />
          </Link>
          <Link to="/">
            <img
              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/ra-logo.svg"
              width="350"
              height="74"
              alt=""
            />
          </Link>
        </div>
        {status === UserStatus.SUCCESS && <AlertMessage type="success" message={message} />}
        {status === UserStatus.ERROR && <AlertMessage type="danger" message={message} />}
        <h3 className="text-gradient mb-0">Sign Up</h3>
        <h5>
          <strong>Digital Membership :</strong>
          <span>£20 / ₹2100 /month</span>
          <br />
          <strong>Club Membership :</strong>
          <span>£2,500 / ₹255,000 /year</span>
        </h5>
        <div className="form_container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="login_content">
              <div className="form-group first-name-field half-col">
                <label>First Name *</label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  autoComplete="off"
                  placeholder="Enter your name"
                  {...register('firstName', {
                    required: true,
                    maxLength: 30,
                    minLength: 1,
                    pattern: /^[a-zA-Z0-9_]+.*$/,
                  })}
                  className={`form-control ${errors.firstName ? 'input_error' : ''}`}
                />
                {errors.firstName?.type === 'required' && (
                  <span className="error-msg">This field is required</span>
                )}
                {errors.firstName?.type === 'maxLength' && (
                  <span className="error-msg"> Max length exceeded</span>
                )}
                {errors.firstName?.type === 'pattern' && (
                  <span className="error-msg"> Invalid name format</span>
                )}
              </div>
              <div className="form-group half-col pr-0">
                <label>Last Name *</label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  autoComplete="off"
                  placeholder="Enter your name"
                  {...register('lastName', {
                    required: true,
                    maxLength: 30,
                    minLength: 1,
                    pattern: /^[a-zA-Z0-9_]+.*$/,
                  })}
                  className={`form-control ${errors.lastName ? 'input_error' : ''}`}
                />
                {errors.lastName?.type === 'required' && (
                  <span className="error-msg">This field is required</span>
                )}
                {errors.lastName?.type === 'maxLength' && (
                  <span className="error-msg"> Max length exceeded</span>
                )}
                {errors.lastName?.type === 'pattern' && (
                  <span className="error-msg"> Invalid name format</span>
                )}
              </div>

              <div className="form-group">
                <label>Email *</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  autoComplete="off"
                  placeholder="Enter your valid email"
                  {...register('email', {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  })}
                  className={`form-control ${errors.email ? 'input_error' : ''}`}
                />
                {errors.email?.type === 'required' && (
                  <span className="error-msg">Email is required</span>
                )}
                {errors.email?.type === 'pattern' && (
                  <span className="error-msg"> Invalid Email format</span>
                )}
              </div>

              <div className="form-group">
                <label>Phone number *</label>
                <div className="select_country_code">
                  <select
                    id="countrycode"
                    name="countrycode"
                    className="select"
                    {...register('countrycode')}
                  >
                    <option value="+91">IN(+91)</option>
                    <option value="+44">UK(+44)</option>
                    <option value="+971">UAE(+971)</option>
                    <option value="+63">PH(+63)</option>
                  </select>
                </div>
                <div className="phone_field_wrap">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="off"
                    placeholder="Enter your phone number"
                    {...register('phone', {
                      required: true,
                      maxLength: 15,
                      minLength: 10,
                      pattern: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                    })}
                    className={`form-control ${errors.phone ? 'input_error' : ''}`}
                  />
                  {errors.phone?.type === 'required' && (
                    <span className="error-msg">Phone Number is required</span>
                  )}
                  {errors.phone?.type === 'maxLength' && (
                    <span className="error-msg"> Enter valid Phone Number</span>
                  )}
                  {errors.phone?.type === 'minLength' && (
                    <span className="error-msg"> Enter valid Phone Number</span>
                  )}
                  {errors.phone?.type === 'pattern' && (
                    <span className="error-msg"> Enter valid Phone Number</span>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label>
                  Password *
                  <p className="password_info">
                    <img
                      src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/infocircle-icon.svg"
                      alt=""
                    />
                    <div className="password_hint_info">
                      <small>
                        Password should contain atleast 8 characters, with at least 1 uppercase, 1
                        lowercase and 1 number
                      </small>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="6"
                        viewBox="0 0 12 6"
                        fill="none"
                      >
                        <path d="M6 6L-2.62268e-07 0L12 -5.24537e-07L6 6Z" fill="#353533" />
                      </svg>
                    </div>
                  </p>
                </label>
                <input
                  type={passwordType}
                  name="password"
                  id="password"
                  autoComplete="off"
                  placeholder="Enter your Password"
                  {...register('password', {
                    required: true,
                    maxLength: 15,
                    minLength: 8,
                    pattern: /^[a-zA-Z0-9_]+.*$/,
                  })}
                  className={`form-control ${errors.password ? 'input_error' : ''}`}
                />
                <div className="passwodicon" color="light" onClick={() => showPassword()}>
                  {passwordType === 'password' && <EyeHideIconSvg />}
                  {passwordType === 'text' && <EyeIconSvg />}
                </div>
                {errors.password?.type === 'required' && (
                  <span className="error-msg">Password is required</span>
                )}
                {errors.password?.type === 'maxLength' && (
                  <span className="error-msg"> Max length exceeded</span>
                )}
                {errors.password?.type === 'minLength' && (
                  <span className="error-msg"> length must be at least 8 characters long</span>
                )}
                {errors.password?.type === 'pattern' && (
                  <span className="error-msg">Invalid password format</span>
                )}
              </div>
              <div className="form-group">
                <label>Confirm Password *</label>
                <input
                  type={cpasswordType}
                  name="confirmpassword"
                  id="confirmpassword"
                  placeholder="Enter your Confirm Password"
                  {...register('confirmpassword', {
                    required: true,
                    validate: {
                      matchesPreviousPassword: (value) => {
                        const { password } = getValues();
                        return password === value || 'Passwords should match!';
                      },
                    },
                  })}
                  className={`form-control ${errors.confirmpassword ? 'input_error' : ''}`}
                />
                <div className="passwodicon" color="light" onClick={() => showCPassword()}>
                  {cpasswordType === 'password' && <EyeHideIconSvg />}
                  {cpasswordType === 'text' && <EyeIconSvg />}
                </div>
                {errors.confirmpassword && (
                  <span className="error_field">{errors.confirmpassword.message}</span>
                )}
                {errors.confirmpassword?.type === 'required' && (
                  <span className="error-msg">Confirm Password is required</span>
                )}
                {errors.confirmpassword?.type === 'maxLength' && (
                  <span className="error-msg"> Max length exceeded</span>
                )}
                {errors.confirmpassword?.type === 'pattern' && (
                  <span className="error-msg">Invalid password format</span>
                )}
              </div>
              <div className="buttons-wrap full_width mt-2">
                <button type="submit" disabled={loadSubmit}>
                  {loadSubmit && <span className="spinner-border spinner-border-sm" />}
                  &nbsp;&nbsp; Get started
                </button>
              </div>
              <div className="social-buttons-wrap hide_block">
                <button type="button">
                  <img
                    src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/apple-icon.svg"
                    width="24"
                    height="24"
                    alt=""
                  />
                  Sign up with Apple
                </button>
                <button type="button">
                  <img
                    src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/google-icon.svg"
                    width="24"
                    height="24"
                    alt=""
                  />
                  Sign up with Google
                </button>
              </div>
            </div>
          </form>
          <div className="allready_account full_width text-center pt-3">
            <p>
              Already have an account?
              <Link to="/login">Log in</Link>
            </p>
          </div>
        </div>
      </div>
      {!deviceIsMobile && (
        <div className="signup_right_container">
          <MyCarousel />
        </div>
      )}
    </div>
  );
}

export default SignUpForm;
function parentUserData(userData: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
}) {
  throw new Error('Function not implemented.');
}
