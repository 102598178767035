import React, { useState } from 'react';
import './Login.css';
import { Link } from 'react-router-dom';
// import { useState } from 'react';
import ForgotEmail from './ForgotEmail';
import { ForgotPasswordVerifyOtp } from './ForgotPasswordVerifyOtp';
import { SetPassword } from './SetPassword';
import ResetPasswordSuccess from './ResetPasswordSuccess';

export enum ResetPasswordSteps {
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  ENTER_OTP = 'ENTER_OTP',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
}
function ForgotPassword() {
  const [step, setStep] = useState<ResetPasswordSteps | ''>(ResetPasswordSteps.FORGOT_PASSWORD);
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [userData, setUserData] = useState('');

  return (
    <section className="signup_container">
      <div className="full_width login_left_wrapper">
        {/* <div className='password_ripple'>
        <img src="/images/forgot-password-ripple-bg.png" alt='' />
      </div> */}
        <div className="password_ripple_effect"></div>
        <div className="password_blur"></div>
        {step === ResetPasswordSteps.FORGOT_PASSWORD && (
          <ForgotEmail
            doStep={setStep}
            setEmail={setEmail}
            parentUserData={setUserData}
            setToken={setToken}
          />
        )}
        {step === ResetPasswordSteps.ENTER_OTP && (
          <ForgotPasswordVerifyOtp
            doStep={setStep}
            userData={userData}
            email={email}
            token={token}
          />
        )}
        {/* {step === ResetPasswordSteps.RESET_PASSWORD && (
          <SetPassword doStep={setStep} email={email} token={token} />
        )} */}
        {step === ResetPasswordSteps.RESET_PASSWORD_SUCCESS && (
          <ResetPasswordSuccess doStep={setStep} />
        )}

        {/* <div className='full_width show_block'>
            <ForgotEmail  />
          </div>
          <div className='full_width hide_block'>
            <ForgotPasswordSuccess  />
          </div>
          <div className='full_width hide_block'>
            <SetPassword  />
          </div>
          <div className='full_width hide_block'>
            <ResetPasswordSuccess  />
          </div> */}
      </div>
    </section>
  );
}

export default ForgotPassword;
