import React, { useState } from 'react';
import './Login.css';
import { Link } from 'react-router-dom';
import SignUpForm from './SignupForm';
import SignupVerification from './SignupVerification';
import SignUpVerificationError from './SignupVerificationError';
import SignUpVerificationSuccess from './SignupVerificationSuccess';

enum UserStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum SignupActivationdSteps {
  SIGN_UP = 'SIGN_UP',
  ENTER_OTP = 'ENTER_OTP',
  ENTER_OTP_ERROR = 'ENTER_OTP_ERROR',
  ENTER_OTP_SUCCESS = 'ENTER_OTP_SUCCESS',
}
export function Signup() {
  // type Inputs = {
  //   firstName: string;
  //   lastName: string;
  //   email: string;
  //   phone: string;
  //   password: string;
  //   countrycode: string;
  // };
  const [step, setStep] = useState<SignupActivationdSteps | ''>(SignupActivationdSteps.SIGN_UP);
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [userData, setUserData] = useState('');

  return (
    <div className="signup_container">
      {step === SignupActivationdSteps.SIGN_UP && (
        <SignUpForm
          doStep={setStep}
          setEmail={setEmail}
          setToken={setToken}
          parentUserData={setUserData}
        />
      )}
      {step === SignupActivationdSteps.ENTER_OTP && (
        <SignupVerification doStep={setStep} userData={userData} token={token} redirect />
      )}
      {step === SignupActivationdSteps.ENTER_OTP_ERROR && (
        <SignUpVerificationError doStep={setStep} setToken={setToken} />
      )}
      {step === SignupActivationdSteps.ENTER_OTP_SUCCESS && (
        <SignUpVerificationSuccess doStep={setStep} />
      )}
    </div>
  );
}

export default Signup;
