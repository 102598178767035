import React, { useState } from 'react';
import './Login.css';
import { Link } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { verifyEmail } from '../../services/user';
import { AlertMessage } from '../common/Messages';

enum UserStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}
export enum ResetPasswordSteps {
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  ENTER_OTP = 'ENTER_OTP',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  ENTER_OTP_SUCCESS = 'ENTER_OTP_SUCCESS',
}

export function ForgotPasswordVerifyOtp({
  doStep,
  email,
  token,
  userData,
}: {
  doStep: Function;
  email: string;
  token: string;
  userData: any;
}) {
  const [message, setMessage] = useState('');
  const [loadSubmit, setLoadSubmit] = useState(false);
  const [status, setStatus] = useState<UserStatus | null>(null);

  type Inputs = {
    token: string;
    otp_token: string;
    email: string;
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({});

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoadSubmit(true);
    // const userData = { token, otp_token: data.otp_token };
    userData.token = token;
    userData.otp = data.otp_token;

    const response = await verifyEmail(userData);
    console.log(response);
    if (response.error) {
      setMessage(response.message);
      setLoadSubmit(false);
      setStatus(UserStatus.ERROR);
    } else {
      console.log(response);
      doStep(ResetPasswordSteps.RESET_PASSWORD_SUCCESS);
    }

    // const { status, message, errors: err, statusCode } = await verifyEmail(userData);

    // setLoadSubmit(false);
    // setStatus(status);
    // setMessage(message);

    // console.log(status);
    // if (status === UserStatus.SUCCESS) {
    //   doStep(ResetPasswordSteps.RESET_PASSWORD);
    // }
  };
  return (
    <div className="middle_container">
      <div className="logo logo-center text-center">
        <Link to="/">
          <img
            src="https://static-web.fra1.digitaloceanspaces.com/royalarion/ra-logo.svg"
            width="281"
            height="59"
            alt=""
          />
        </Link>
      </div>
      <div className="form_container password_form_container">
        <form
          id="contact-form1"
          className="get-a-quote"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="text-center">
            <div className="heading_content">
              <img src="/images/password-icon.svg" alt="" width="56" height="56" />
              <h2 className="text-gradient">Forgot Password</h2>
              <p className="p-0">No worries, we'll send you reset instructions.</p>
            </div>
          </div>
          {status === UserStatus.SUCCESS && <AlertMessage type="success" message={message} />}
          {status === UserStatus.ERROR && <AlertMessage type="danger" message={message} />}
          <div className="login_content">
            <div className="form-group">
              <label>Otp Verification *</label>
              <input
                type="text"
                placeholder="Enter verification code"
                name="otp_token"
                maxLength={4}
                minLength={4}
                id="otp_token"
                {...register('otp_token', { required: true, maxLength: 4, minLength: 4 })}
                className={`form-control ${errors.otp_token ? 'input_error' : ''}`}
              />
              {errors.otp_token?.type === 'required' && (
                <span className="error-msg">This field is required</span>
              )}
              {errors.otp_token?.type === 'maxLength' && (
                <span className="error-msg"> Max length exceeded</span>
              )}
            </div>
            <div className="buttons-wrap full_width mt-2">
              <button type="submit" disabled={loadSubmit}>
                {loadSubmit && <span className="spinner-border spinner-border-sm" />}
                &nbsp;&nbsp; Reset Password
              </button>
            </div>
          </div>
          <div className="back_login_link full_width">
            <Link to="/login">
              <img src="/images/arrow-left-icon.svg" alt="" width="20" height="20" />
              Back to Login
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
