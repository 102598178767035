import LocalStorageService from 'components/_Services/LocalStorageService';

export const getHeaderInfo = async function () {
  const userData = await LocalStorageService.getItem('userInfo');
  const userInfo = JSON.parse(userData);

  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
  };
};

export const getFormDataHeader = async function () {
  const userData = await LocalStorageService.getItem('userInfo');
  const userInfo = JSON.parse(userData);
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${userInfo.token}`,
    },
  };
};
