import { post, get, put } from 'components/_Services/HttpService';
import { PaymentModel, PaymentStatusModel } from '../types/payment.model';
import http from './http-common';

const BASE_URL = process.env.REACT_APP_AUTHENTICATION_API_URL;
// const BASE_URL = 'https://6ags7buu6hmrnp3sb77zs6edae0zvxgd.lambda-url.eu-west-2.on.aws';

export const addPayment = async (data: PaymentModel) => {
  const response = await post(`${BASE_URL}/stripe/checkoutsession`, data);
  return response;
  console.log(response);
};

export const paymentStatus = async (data: PaymentStatusModel) => {
  const response = await post(`${BASE_URL}/stripe/checksession`, data);
  return response;
  console.log(response);
};
// export const paymentStatus = async (data: PaymentStatusModel) =>
//   http
//     .post<PaymentStatusModel>(`${BASE_URL}/stripe/checksession`, data)
//     .then((response: any) => ({
//       ...response.data,
//       statusCode: response.status,
//     }))
//     .catch((e: any) => ({
//       message: e.response?.data.error || e.message,
//       errors: e.response?.data?.errorCode,
//       error: true,
//       statusCode: e.response.status,
//       status: e.response?.data.status,
//     }));
