import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Calendar1 from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Header from 'components/Auth/Header';
import { getEventsList } from 'services/events';
import moment from 'moment';
import { MinusIconSvg, PlusIconSvg } from '../App/SvgIcons';

function Home() {
  const [bespokeAppData, bespokeAppValue] = useState('my_stable');
  const bespokeApp = async (val) => {
    bespokeAppValue(val);
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  function ButtonGroup({ next, previous, goToSlide, ...rest }) {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group">
        <button className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()}>
          <img
            src="https://static-web.fra1.digitaloceanspaces.com/royalarion/chevron-left-icon.svg"
            alt=""
          />
        </button>
        <button onClick={() => goToSlide(currentSlide + 1)}>
          <img
            src="https://static-web.fra1.digitaloceanspaces.com/royalarion/chevron-right-icon.svg"
            alt=""
          />
        </button>
      </div>
    );
  }
  const [bookingDate, setBookingDate] = useState(new Date());
  const setDate = (newDate) => {
    setBookingDate(newDate);
  };
  const filterByMonth = [];
  const [events, setEvents] = useState([]);
  // const currentMonth = ;
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedValues, setSelectedValues] = useState([]);
  const [originalevents, setOriginalEvents] = useState([]);
  const getEventData = async () => {
    const data = await getEventsList();

    setOriginalEvents(data);
    filterData(data, currentMonth, currentYear);
  };
  const filterData = (data, CMonth, CYear) => {
    //console.log(CMonth + '---' + CYear);
    const formatedData = data.map((v: any) => {
      const startDate = new Date(v.event_start_date);
      const stratMonth = startDate.getMonth() + 1;
      const stratYear = startDate.getFullYear();
      const stratDate = startDate.getDate();

      if (stratMonth === CMonth && stratYear === CYear) {
        if (filterByMonth[stratMonth] === undefined && filterByMonth[stratMonth] !== '') {
          filterByMonth[stratMonth] = [];
        }
        filterByMonth[stratMonth].push(v);
      }
      setEvents(filterByMonth);
      const formattedMonth = stratMonth < 10 ? `0${stratMonth}` : stratMonth;
      const formattedDate = stratDate < 10 ? `0${stratDate}` : stratDate;
      return `${formattedDate}-${formattedMonth}-${stratYear}`;
    });

    setSelectedValues(formatedData);
  };
  const goToNextMonth = () => {
    const newDate = bookingDate;

    newDate.setMonth(newDate.getMonth() + 1);
    setCurrentMonth(newDate.getMonth() + 1);
    setBookingDate(newDate);

    filterData(originalevents, newDate.getMonth() + 1, newDate.getFullYear());
  };
  const goToPrevioustMonth = () => {
    const newDate = bookingDate;
    //console.log(newDate);
    newDate.setMonth(newDate.getMonth() - 1);
    //console.log(newDate);
    setBookingDate(newDate);
    filterData(originalevents, newDate.getMonth() + 1, newDate.getFullYear());
  };

  useEffect(() => {
    getEventData();
  }, []);

  return (
    <>
      <Header />
      <div className="full_block">
        {/* start of banner container */}
        <div className="banner_container">
          <div className="container">
            <div className="row header_row">
              {/* <div className="ripple_img">
      <img
        src="https://static-web.fra1.digitaloceanspaces.com/royalarion/ripple-main-img.png"
        alt=""
      />
    </div> */}
              <div className="circle-ripple" />
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="banner_content">
                  <h1>
                    Join The Royal Arion Club and
                    <br />
                    become a Racehorse Owner
                  </h1>
                  <p>
                    The Royal Arion Club offers enhanced, shared racehorse ownership with horses in
                    India, the UK and beyond. Alongside our brand partners we deliver membership
                    privileges, curated experiences and entertainment.
                  </p>
                  <div className="memberships_links_wrapper">
                    <ul>
                      <li>
                        <Link to="/signup">Digital Membership</Link>
                        <span>£20 / ₹2100</span>
                        <small>/Month</small>
                      </li>
                      <li>
                        <Link to="/signup">Club Membership</Link>
                        <span>£2500 / ₹255,000</span>
                        <small>/Year</small>
                      </li>
                    </ul>
                  </div>
                  <div className="banner_links">
                    <Link to="/signup" className="member_link">
                      Sign up
                    </Link>
                  </div>
                </div>
              </div>
              <div className="blur_circle" />
            </div>
          </div>
        </div>
        {/* end of banner container */}
        {/* start of video container */}
        <div className="video_container padding_bottom40">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h3 className="text-gradient text-center">Enhance your Horse Racing experience</h3>
                <div className="video_embed_container">
                  <video
                    width="1010"
                    height="568"
                    controls
                    playsInline
                    poster="https://static-web.fra1.digitaloceanspaces.com/royalarion/launch-video-img.png"
                  >
                    <source
                      src="https://static-web.fra1.digitaloceanspaces.com/royalarion/launch-video.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of video container */}
        {/* start of our horses container */}
        <div className="our_horses_container">
          <div className="container">
            <div className="row">
              <div className="our_horses_content col-12">
                <div className="heading">
                  <h3 className="text-gradient">Own Shares in our Horses</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="our_horses_carousel">
                  <Carousel
                    responsive={responsive}
                    containerClass="carousel-container"
                    keyBoardControl
                    infinite
                    itemClass="carousel-item-padding-40-px"
                    arrows={false}
                    renderButtonGroupOutside
                    customButtonGroup={
                      <ButtonGroup next={undefined} previous={undefined} goToSlide={undefined} />
                    }
                  >
                    <div className="horse_item">
                      <div className="horse_item_wrapper">
                        <div className="horse_item_container front">
                          <div className="img_wrapper full_width">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/royal-elysian-lr-img.png"
                              alt=""
                            />
                          </div>
                          <div className="horse_item_content full_width">
                            <div className="horse_item_info full_width">
                              <h5>Royal Elysian</h5>
                              <div className="horse_age_info">
                                <small>Age:</small>
                                <strong>3 Years Old</strong>
                              </div>
                              <div className="horse_gender_info">
                                <img
                                  src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/gender-filly.svg"
                                  alt=""
                                />
                                <span>Filly</span>
                              </div>
                            </div>
                            <div className="horse_loc_info full_width">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/uk-flag-rect.png"
                                alt=""
                              />
                              <span>Newmarket</span>
                            </div>
                          </div>
                        </div>
                        <div className="horse_item_container back">
                          <div className="img_wrapper full_width">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/royal-elysian-cr-img.png"
                              alt=""
                            />
                          </div>
                          <div className="horse_item_content full_width">
                            <div className="horse_item_info full_width">
                              <h5>Royal Elysian</h5>
                              <div className="horse_age_info">
                                <small>Age:</small>
                                <strong>3 Years Old</strong>
                              </div>
                              <div className="horse_gender_info">
                                <img
                                  src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/gender-filly.svg"
                                  alt=""
                                />
                                <span>Filly</span>
                              </div>
                            </div>
                            <div className="horse_loc_info full_width">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/uk-flag-rect.png"
                                alt=""
                              />
                              <span>Newmarket</span>
                            </div>
                            <div className="horse_tr_sr_info full_width">
                              <ul className="full_width">
                                <li className="hr_tr_info">
                                  <span>Trainer</span>
                                  <strong>James Ferguson</strong>
                                </li>
                                <li className="hr_sr_dm_info">
                                  <span>Sire/Dam</span>
                                  <strong>Showcasing/ Cloris</strong>
                                </li>
                              </ul>
                            </div>
                            <div className="horse_history_info full_width">
                              <ul className="full_width">
                                <li>
                                  <span>Runs</span>
                                  <strong>4</strong>
                                </li>
                                <li>
                                  <span>Wins</span>
                                  <strong>1</strong>
                                </li>
                                <li>
                                  <span>Places</span>
                                  <strong>2</strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="horse_item">
                      <div className="horse_item_wrapper">
                        <div className="horse_item_container front">
                          <div className="img_wrapper full_width">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/coeur-de-lion-lr-img.png"
                              alt=""
                            />
                          </div>
                          <div className="horse_item_content full_width">
                            <div className="horse_item_info full_width">
                              <h5>Coeur De Lion</h5>
                              <div className="horse_age_info">
                                <small>Age:</small>
                                <strong>5 Years Old</strong>
                              </div>
                              <div className="horse_gender_info">
                                <img
                                  src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/gender-female.svg"
                                  alt=""
                                />
                                <span>Colt</span>
                              </div>
                            </div>
                            <div className="horse_loc_info full_width">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-flag-rect.png"
                                alt=""
                              />
                              <span>Mumbai, In</span>
                            </div>
                          </div>
                        </div>
                        <div className="horse_item_container back">
                          <div className="img_wrapper full_width">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/coeur-de-lion-cr-img.png"
                              alt=""
                            />
                          </div>
                          <div className="horse_item_content full_width">
                            <div className="horse_item_info full_width">
                              <h5>Coeur De Lion</h5>
                              <div className="horse_age_info">
                                <small>Age:</small>
                                <strong>5 Years Old</strong>
                              </div>
                              <div className="horse_gender_info">
                                <img
                                  src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/gender-female.svg"
                                  alt=""
                                />
                                <span>Colt</span>
                              </div>
                            </div>
                            <div className="horse_loc_info full_width">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-flag-rect.png"
                                alt=""
                              />
                              <span>Mumbai, In</span>
                            </div>
                            <div className="horse_tr_sr_info full_width">
                              <ul className="full_width">
                                <li className="hr_tr_info">
                                  <span>Trainer</span>
                                  <strong>Narendra Lagad</strong>
                                </li>
                                <li className="hr_sr_dm_info">
                                  <span>Sire/Dam</span>
                                  <strong>Roderic O'Connor/ Sweeping Star</strong>
                                </li>
                              </ul>
                            </div>
                            <div className="horse_history_info full_width">
                              <ul className="full_width">
                                <li>
                                  <span>Runs</span>
                                  <strong>22</strong>
                                </li>
                                <li>
                                  <span>Wins</span>
                                  <strong>7</strong>
                                </li>
                                <li>
                                  <span>Places</span>
                                  <strong>6</strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="horse_item">
                      <div className="horse_item_wrapper">
                        <div className="horse_item_container front">
                          <div className="img_wrapper full_width">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/chinese-whisper-royal-lr-img.png"
                              alt=""
                            />
                          </div>
                          <div className="horse_item_content full_width">
                            <div className="horse_item_info full_width">
                              <h5>Royal Whisper</h5>
                              <div className="horse_age_info">
                                <small>Age:</small>
                                <strong>3 Years Old</strong>
                              </div>
                              <div className="horse_gender_info">
                                <img
                                  src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/gender-filly.svg"
                                  alt=""
                                />
                                <span>Filly</span>
                              </div>
                            </div>
                            <div className="horse_loc_info full_width">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-flag-rect.png"
                                alt=""
                              />
                              <span>Bangalore, In</span>
                            </div>
                          </div>
                        </div>
                        <div className="horse_item_container back">
                          <div className="img_wrapper full_width">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/chinese-whisper-royal-cr-img.png"
                              alt=""
                            />
                          </div>
                          <div className="horse_item_content full_width">
                            <div className="horse_item_info full_width">
                              <h5>Royal Whisper</h5>
                              <div className="horse_age_info">
                                <small>Age:</small>
                                <strong>3 Years Old</strong>
                              </div>
                              <div className="horse_gender_info">
                                <img
                                  src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/gender-filly.svg"
                                  alt=""
                                />
                                <span>Filly</span>
                              </div>
                            </div>
                            <div className="horse_loc_info full_width">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-flag-rect.png"
                                alt=""
                              />
                              <span>Bangalore, In</span>
                            </div>
                            <div className="horse_tr_sr_info full_width">
                              <ul className="full_width">
                                <li className="hr_tr_info">
                                  <span>Trainer</span>
                                  <strong>Neil Devaney</strong>
                                </li>
                                <li className="hr_sr_dm_info">
                                  <span>Sire/Dam</span>
                                  <strong>Chinese Whisper/ Royal Superlative</strong>
                                </li>
                              </ul>
                            </div>
                            <div className="horse_history_info full_width">
                              <ul className="full_width">
                                <li>
                                  <span>Runs</span>
                                  <strong>1</strong>
                                </li>
                                <li>
                                  <span>Wins</span>
                                  <strong>-</strong>
                                </li>
                                <li>
                                  <span>Places</span>
                                  <strong>-</strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="horse_item">
                      <div className="horse_item_wrapper">
                        <div className="horse_item_container front">
                          <div className="img_wrapper full_width">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/charline-royale-lr-img.png"
                              alt=""
                            />
                          </div>
                          <div className="horse_item_content full_width">
                            <div className="horse_item_info full_width">
                              <h5>Sergei Prokofiev x Charline Royale</h5>
                              <div className="horse_age_info">
                                <small>Age:</small>
                                <strong>2 Years Old</strong>
                              </div>
                              <div className="horse_gender_info">
                                <img
                                  src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/gender-filly.svg"
                                  alt=""
                                />
                                <span>Filly</span>
                              </div>
                            </div>
                            <div className="horse_loc_info full_width">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/uk-flag-rect.png"
                                alt=""
                              />
                              <span>Newbury, UK</span>
                            </div>
                          </div>
                        </div>
                        <div className="horse_item_container back">
                          <div className="img_wrapper full_width">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/charline-royale-cr-img.png"
                              alt=""
                            />
                          </div>
                          <div className="horse_item_content full_width">
                            <div className="horse_item_info full_width">
                              <h5>Sergei Prokofiev x Charline Royale</h5>
                              <div className="horse_age_info">
                                <small>Age:</small>
                                <strong>2 Years Old</strong>
                              </div>
                              <div className="horse_gender_info">
                                <img
                                  src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/gender-filly.svg"
                                  alt=""
                                />
                                <span>Filly</span>
                              </div>
                            </div>
                            <div className="horse_loc_info full_width">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/uk-flag-rect.png"
                                alt=""
                              />
                              <span>Newbury, UK</span>
                            </div>
                            <div className="horse_tr_sr_info full_width">
                              <ul className="full_width">
                                <li className="hr_tr_info">
                                  <span>Trainer</span>
                                  <strong>Andrew Balding</strong>
                                </li>
                                <li className="hr_sr_dm_info">
                                  <span>Sire/Dam</span>
                                  <strong>Sergei Prokofiev /Charline Royale (Ire)</strong>
                                </li>
                              </ul>
                            </div>
                            <div className="horse_history_info full_width">
                              <ul className="full_width">
                                <li>
                                  <span>Runs</span>
                                  <strong>-</strong>
                                </li>
                                <li>
                                  <span>Wins</span>
                                  <strong>-</strong>
                                </li>
                                <li>
                                  <span>Places</span>
                                  <strong>-</strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="horse_item">
                      <div className="horse_item_wrapper">
                        <div className="horse_item_container front">
                          <div className="img_wrapper full_width">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/fascinating-rock-lr-img.png"
                              alt=""
                            />
                          </div>
                          <div className="horse_item_content full_width">
                            <div className="horse_item_info full_width">
                              <h5>Rock and Royal</h5>
                              <div className="horse_age_info">
                                <small>Age:</small>
                                <strong>3 Years Old</strong>
                              </div>
                              <div className="horse_gender_info">
                                <img
                                  src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/gender-filly.svg"
                                  alt=""
                                />
                                <span>Filly</span>
                              </div>
                            </div>
                            <div className="horse_loc_info full_width">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/uk-flag-rect.png"
                                alt=""
                              />
                              <span>NewMarket</span>
                            </div>
                          </div>
                        </div>
                        <div className="horse_item_container back">
                          <div className="img_wrapper full_width">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/fascinating-rock-cr-img.png"
                              alt=""
                            />
                          </div>
                          <div className="horse_item_content full_width">
                            <div className="horse_item_info full_width">
                              <h5>Rock and Royal</h5>
                              <div className="horse_age_info">
                                <small>Age:</small>
                                <strong>3 Years Old</strong>
                              </div>
                              <div className="horse_gender_info">
                                <img
                                  src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/gender-filly.svg"
                                  alt=""
                                />
                                <span>Filly</span>
                              </div>
                            </div>
                            <div className="horse_loc_info full_width">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/uk-flag-rect.png"
                                alt=""
                              />
                              <span>NewMarket</span>
                            </div>
                            <div className="horse_tr_sr_info full_width">
                              <ul className="full_width">
                                <li className="hr_tr_info">
                                  <span>Trainer</span>
                                  <strong>Dylan Cunha</strong>
                                </li>
                                <li className="hr_sr_dm_info">
                                  <span>Sire/Dam</span>
                                  <strong>Fascinating rock/ Spesialta</strong>
                                </li>
                              </ul>
                            </div>
                            <div className="horse_history_info full_width">
                              <ul className="full_width">
                                <li>
                                  <span>Runs</span>
                                  <strong>-</strong>
                                </li>
                                <li>
                                  <span>Wins</span>
                                  <strong>-</strong>
                                </li>
                                <li>
                                  <span>Places</span>
                                  <strong>-</strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="horse_item">
                      <div className="horse_item_wrapper">
                        <div className="horse_item_container front">
                          <div className="img_wrapper full_width">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/leitir-mor-lr-img.png"
                              alt=""
                            />
                          </div>
                          <div className="horse_item_content full_width">
                            <div className="horse_item_info full_width">
                              <h5>Leitir Mor x Stravella</h5>
                              <div className="horse_age_info">
                                <small>Age:</small>
                                <strong>3 Years Old</strong>
                              </div>
                              <div className="horse_gender_info">
                                <img
                                  src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/gender-filly.svg"
                                  alt=""
                                />
                                <span>Filly</span>
                              </div>
                            </div>
                            <div className="horse_loc_info full_width">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-flag-rect.png"
                                alt=""
                              />
                              <span>Hyderabad, In</span>
                            </div>
                          </div>
                        </div>
                        <div className="horse_item_container back">
                          <div className="img_wrapper full_width">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/leitir-mor-cr-img.png"
                              alt=""
                            />
                          </div>
                          <div className="horse_item_content full_width">
                            <div className="horse_item_info full_width">
                              <h5>Leitir Mor x Stravella</h5>
                              <div className="horse_age_info">
                                <small>Age:</small>
                                <strong>3 Years Old</strong>
                              </div>
                              <div className="horse_gender_info">
                                <img
                                  src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/gender-filly.svg"
                                  alt=""
                                />
                                <span>Filly</span>
                              </div>
                            </div>
                            <div className="horse_loc_info full_width">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-flag-rect.png"
                                alt=""
                              />
                              <span>Hyderabad, In</span>
                            </div>
                            <div className="horse_tr_sr_info full_width">
                              <ul className="full_width">
                                <li className="hr_tr_info">
                                  <span>Trainer</span>
                                  <strong>Hilton Sequera</strong>
                                </li>
                                <li className="hr_sr_dm_info">
                                  <span>Sire/Dam</span>
                                  <strong>Leitir Mor x Stravella</strong>
                                </li>
                              </ul>
                            </div>
                            <div className="horse_history_info full_width">
                              <ul className="full_width">
                                <li>
                                  <span>Runs</span>
                                  <strong>-</strong>
                                </li>
                                <li>
                                  <span>Wins</span>
                                  <strong>-</strong>
                                </li>
                                <li>
                                  <span>Places</span>
                                  <strong>-</strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="horse_item">
                      <div className="horse_item_wrapper">
                        <div className="horse_item_container front">
                          <div className="img_wrapper full_width">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/gusto-fearless-lr-img.png"
                              alt=""
                            />
                          </div>
                          <div className="horse_item_content full_width">
                            <div className="horse_item_info full_width">
                              <h5>Royal Decan</h5>
                              <div className="horse_age_info">
                                <small>Age:</small>
                                <strong>3 Years Old</strong>
                              </div>
                              <div className="horse_gender_info">
                                <img
                                  src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/gender-female.svg"
                                  alt=""
                                />
                                <span>Colt</span>
                              </div>
                            </div>
                            <div className="horse_loc_info full_width">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-flag-rect.png"
                                alt=""
                              />
                              <span>Bangalore, In</span>
                            </div>
                          </div>
                        </div>
                        <div className="horse_item_container back">
                          <div className="img_wrapper full_width">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/gusto-fearless-cr-img.png"
                              alt=""
                            />
                          </div>
                          <div className="horse_item_content full_width">
                            <div className="horse_item_info full_width">
                              <h5>Royal Decan</h5>
                              <div className="horse_age_info">
                                <small>Age:</small>
                                <strong>3 Years Old</strong>
                              </div>
                              <div className="horse_gender_info">
                                <img
                                  src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/gender-female.svg"
                                  alt=""
                                />
                                <span>Colt</span>
                              </div>
                            </div>
                            <div className="horse_loc_info full_width">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-flag-rect.png"
                                alt=""
                              />
                              <span>Bangalore, In</span>
                            </div>
                            <div className="horse_tr_sr_info full_width">
                              <ul className="full_width">
                                <li className="hr_tr_info">
                                  <span>Trainer</span>
                                  <strong>Arjun Mangalorkar</strong>
                                </li>
                                <li className="hr_sr_dm_info">
                                  <span>Sire/Dam</span>
                                  <strong>Gusto/ Fearless Nadia</strong>
                                </li>
                              </ul>
                            </div>
                            <div className="horse_history_info full_width">
                              <ul className="full_width">
                                <li>
                                  <span>Runs</span>
                                  <strong>-</strong>
                                </li>
                                <li>
                                  <span>Wins</span>
                                  <strong>-</strong>
                                </li>
                                <li>
                                  <span>Places</span>
                                  <strong>-</strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="horse_item">
                      <div className="horse_item_wrapper">
                        <div className="horse_item_container front">
                          <div className="img_wrapper full_width">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/chinese-whisper-socharm-lr-img.png"
                              alt=""
                            />
                          </div>
                          <div className="horse_item_content full_width">
                            <div className="horse_item_info full_width">
                              <h5>Emperors Charm</h5>
                              <div className="horse_age_info">
                                <small>Age:</small>
                                <strong>3 Years Old</strong>
                              </div>
                              <div className="horse_gender_info">
                                <img
                                  src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/gender-female.svg"
                                  alt=""
                                />
                                <span>Colt</span>
                              </div>
                            </div>
                            <div className="horse_loc_info full_width">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-flag-rect.png"
                                alt=""
                              />
                              <span>Hyderabad, In</span>
                            </div>
                          </div>
                        </div>
                        <div className="horse_item_container back">
                          <div className="img_wrapper full_width">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/chinese-whisper-socharm-cr-img.png"
                              alt=""
                            />
                          </div>
                          <div className="horse_item_content full_width">
                            <div className="horse_item_info full_width">
                              <h5>Emperors Charm</h5>
                              <div className="horse_age_info">
                                <small>Age:</small>
                                <strong>3 Years Old</strong>
                              </div>
                              <div className="horse_gender_info">
                                <img
                                  src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/gender-female.svg"
                                  alt=""
                                />
                                <span>Colt</span>
                              </div>
                            </div>
                            <div className="horse_loc_info full_width">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-flag-rect.png"
                                alt=""
                              />
                              <span>Hyderabad, In</span>
                            </div>
                            <div className="horse_tr_sr_info full_width">
                              <ul className="full_width">
                                <li className="hr_tr_info">
                                  <span>Trainer</span>
                                  <strong>Hilton Sequera</strong>
                                </li>
                                <li className="hr_sr_dm_info">
                                  <span>Sire/Dam</span>
                                  <strong>Chinese Whisper x So Charm</strong>
                                </li>
                              </ul>
                            </div>
                            <div className="horse_history_info full_width">
                              <ul className="full_width">
                                <li>
                                  <span>Runs</span>
                                  <strong>-</strong>
                                </li>
                                <li>
                                  <span>Wins</span>
                                  <strong>-</strong>
                                </li>
                                <li>
                                  <span>Places</span>
                                  <strong>-</strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="horse_item">
                      <div className="horse_item_wrapper">
                        <div className="horse_item_container front">
                          <div className="img_wrapper full_width">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/eloquent-lr-img.png"
                              alt=""
                            />
                          </div>
                          <div className="horse_item_content full_width">
                            <div className="horse_item_info full_width">
                              <h5>Eloquent</h5>
                              <div className="horse_age_info">
                                <small>Age:</small>
                                <strong>4 Years Old</strong>
                              </div>
                              <div className="horse_gender_info">
                                <img
                                  src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/gender-female.svg"
                                  alt=""
                                />
                                <span>Gelding</span>
                              </div>
                            </div>
                            <div className="horse_loc_info full_width">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-flag-rect.png"
                                alt=""
                              />
                              <span>Mumbai, India</span>
                            </div>
                          </div>
                        </div>
                        <div className="horse_item_container back">
                          <div className="img_wrapper full_width">
                            <img
                              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/eloquent-cr-img.png"
                              alt=""
                            />
                          </div>
                          <div className="horse_item_content full_width">
                            <div className="horse_item_info full_width">
                              <h5>Eloquent</h5>
                              <div className="horse_age_info">
                                <small>Age:</small>
                                <strong>4 Years Old</strong>
                              </div>
                              <div className="horse_gender_info">
                                <img
                                  src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/gender-female.svg"
                                  alt=""
                                />
                                <span>Gelding</span>
                              </div>
                            </div>
                            <div className="horse_loc_info full_width">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-flag-rect.png"
                                alt=""
                              />
                              <span>Mumbai, India</span>
                            </div>
                            <div className="horse_tr_sr_info full_width">
                              <ul className="full_width">
                                <li className="hr_tr_info">
                                  <span>Trainer</span>
                                  <strong>Shazaan Shah</strong>
                                </li>
                                <li className="hr_sr_dm_info">
                                  <span>Sire/Dam</span>
                                  <strong>Speaking of Which / Auntie Kathryn</strong>
                                </li>
                              </ul>
                            </div>
                            <div className="horse_history_info full_width">
                              <ul className="full_width">
                                <li>
                                  <span>Runs</span>
                                  <strong>10</strong>
                                </li>
                                <li>
                                  <span>Wins</span>
                                  <strong>1</strong>
                                </li>
                                <li>
                                  <span>Places</span>
                                  <strong>1</strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel>
                  ;
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of our horses container */}
        {/* start of regular events container */}
        <div className="regular_events_container">
          <div className="container">
            <div className="row">
              <div className="regular_events_content col-12">
                <div className="heading">
                  <h3 className="text-gradient">
                    Attend Regular Racing, Social and Sporting events
                  </h3>
                </div>
                <div className="regular_events_wrapper full_width">
                  <div className="row">
                    <div className="col-lg-4 events_calendar_content">
                      <div className="schedule_time_wrapper full_width regular_events_calendar">
                        <Calendar1
                          //onChange={setDate}
                          value={bookingDate}
                          tileClassName={({ date, view }) => {
                            if (
                              selectedValues &&
                              selectedValues.find((x) => x === moment(date).format('DD-MM-YYYY'))
                            ) {
                              return 'highlight';
                            }
                          }}
                        />
                        <div className="calendar_nav_btns full_width">
                          <button className="cal_prev_btn" onClick={goToPrevioustMonth}>
                            Prev
                          </button>
                          <button className="cal_next_btn" onClick={goToNextMonth}>
                            Next
                          </button>
                        </div>
                      </div>
                      <Link className="view_events" to="/signup">
                        View All Events
                      </Link>
                    </div>
                    <div className="col-lg-8">
                      <div className="monthly_events_wrapper full_width">
                        <div
                          className={`${events.length === 0 ? 'no_events_wrapper' : 'hide_block'}`}
                        >
                          <img
                            src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/error-fill-icon.svg"
                            width="24"
                            height="24"
                            alt=""
                          />
                          <p>No events found on the selected month</p>
                        </div>
                        {events?.map((eventdata: any, index) => (
                          <div key={index} className=" full_width">
                            {eventdata?.map((event) => (
                              <div key={index} className="monlthy_events_list full_width">
                                <h5>
                                  {moment.utc(event.event_start_date).format('Do [of] MMMM, YYYY')}
                                </h5>
                                <div className="monthly_events_content full_width">
                                  <div className="img_wrapper">
                                    <img src={event.image_url} alt="" />
                                  </div>
                                  <div className="text_content">
                                    <h6>{event.events_title}</h6>
                                    <p
                                      className={`${event.description === '' ? 'hide_block' : ''}`}
                                      dangerouslySetInnerHTML={{
                                        __html: event?.description,
                                      }}
                                    ></p>
                                    <p
                                      className={`${event.description !== '' ? 'hide_block' : ''}`}
                                      dangerouslySetInnerHTML={{
                                        __html: event?.full_description,
                                      }}
                                    ></p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                        {/* <div className="monlthy_events_list full_width">
                          <h5>26th of March, 2024</h5>
                          <div className="monthly_events_content full_width">
                            <div className="img_wrapper">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/dubai-worldcup-img.jpg"
                                alt=""
                              />
                            </div>
                            <div className="text_content">
                              <h6>Dubai World Cup</h6>
                              <p>
                                Experience one of the Worlds richest race days and attend our
                                celebrity drinks evening!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="monlthy_events_list full_width">
                          <h5>11th of April, 2024</h5>
                          <div className="monthly_events_content full_width">
                            <div className="img_wrapper">
                              <img
                                src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/master-golf-img-1.jpg"
                                alt=""
                              />
                            </div>
                            <div className="text_content">
                              <h6>The Masters Golf</h6>
                              <p>
                                Experience the epitome of luxury with a once-in-a-lifetime holiday
                                to The Masters, one of golf's most prestigious and revered
                                tournaments.
                              </p>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of regular events container */}
        {/* start of services container */}
        <div className="services_container padding_top40">
          <div className="container">
            <div className="row">
              <div className="services_content col-12">
                <div className="heading">
                  <h3 className="text-gradient">Membership benefits</h3>
                </div>
                <div className="services_wrapper full_width">
                  <div className="service_item service_item_large">
                    <div className="service_image">
                      <img
                        src="https://static-web.fra1.digitaloceanspaces.com/royalarion/racehorse-ownership-img.png"
                        alt=""
                      />
                    </div>
                    <div className="service_details">
                      <h5>Enhanced Racehorse Ownership</h5>
                      <p>
                        Members own club shares in all the club horses (currently 3 in the UK and 6
                        in India), whilst being treated as if they are your very own. Compared to
                        traditional racehorse ownership, this innovative approach offers an
                        all-inclusive, immersive and risk free experience. Access the Royal Arion
                        interactive App, follow the horses, receive regular owners invitations to
                        the stables, racing and social events. Share of the prize money.
                      </p>
                    </div>
                  </div>
                  <div className="service_item service_item_large mr-0">
                    <div className="service_image">
                      <img
                        src="https://static-web.fra1.digitaloceanspaces.com/royalarion/events-cal-lifestyle.png"
                        alt=""
                      />
                    </div>
                    <div className="service_details">
                      <h5>Events Calendar and Lifestyle Edtorial</h5>
                      <p>
                        As well regular members events at the stables, sales and at the racecourse,
                        Club members will be invited to exclusive social, sporting and racing
                        events. Offered through our calendar of events.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="services_wrapper full_width">
                  <div className="service_item service_item_small">
                    <div className="service_image">
                      <img
                        src="https://static-web.fra1.digitaloceanspaces.com/royalarion/equine-concierge-member-img.png"
                        alt=""
                      />
                    </div>
                    <div className="service_details">
                      <h5>Equine Concierge</h5>
                      <p>
                        Our racing advisors assist members with sole racehorse ownership or
                        syndicate creation, offering guidance and support. Existing owners benefit
                        from our racing and bloodstock management service, receiving regular
                        updates, video content, and horse data through our Club App
                      </p>
                    </div>
                  </div>
                  <div className="service_item service_item_medium mr-0">
                    <div className="service_image">
                      <img
                        src="https://static-web.fra1.digitaloceanspaces.com/royalarion/luxurary-partner-benefits-img.png"
                        alt=""
                      />
                    </div>
                    <div className="service_details">
                      <h5>
                        Luxury Partner
                        <br />
                        Benefits
                      </h5>
                      <p>
                        Privileged access to our carefully selected collection of international
                        luxury brand partners, delivering a range of unique experiences, editorial,
                        benefits, special offers and discounted rates to our members.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="services_wrapper full_width">
                  <div className="service_item service_item_large service_item_ex_large">
                    <div className="service_image">
                      <img
                        src="https://static-web.fra1.digitaloceanspaces.com/royalarion/polo-riding-img.jpg"
                        alt=""
                      />
                    </div>
                    <div className="service_details">
                      <h5>Polo & Riding</h5>
                      <p>
                        At our race locations worldwide, members can build stronger bonds with
                        horses. At partner equestrian sites, they can learn riding or polo with
                        skilled instructors, becoming true horse enthusiasts.
                      </p>
                    </div>
                  </div>
                  <div className="service_item service_item_large service_item_ex_large mr-0">
                    <div className="service_image">
                      <img
                        src="https://static-web.fra1.digitaloceanspaces.com/royalarion/philanthropy-img.jpg"
                        alt=""
                      />
                    </div>
                    <div className="service_details">
                      <h5>Philanthropy & Scholarships</h5>
                      <p>
                        Supporting causes and communities we care about, the annual Royal Arion
                        Jockey Scholarship aids a jockey's career by funding their training course
                        at the British Racing School.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of services container */}
        {/* start of app experience container */}
        <div className="app_experience_container">
          <div className="experience_ripple_img">
            <img
              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/exp-ripple-bg.png"
              alt=""
            />
          </div>
          <div className="container">
            <div className="row app_experience_row">
              <div className="app_experience_content col-12">
                <div className="heading">
                  <h3 className="text-gradient">Bespoke app experience</h3>
                  <p>
                    Elevate your racing journey as part of an elite community. Access extraordinary
                    experiences within the Royal Arion Club app.
                  </p>
                </div>
                <div className="app_experience_wrapper full_width">
                  <div className="row">
                    <div className="col-lg-4 col-md-4">
                      <div className="experience_accordion_container">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item
                            eventKey="0"
                            onClick={() => bespokeApp('my_stable')}
                            className={`save_action ${
                              bespokeAppData === 'my_stable' ? 'active' : 'inactive'
                            }`}
                          >
                            <Accordion.Header>
                              My Stable
                              <span className="action_icon plus_icon">
                                <PlusIconSvg />
                              </span>
                              <span className="action_icon minus_icon">
                                <MinusIconSvg />
                              </span>
                            </Accordion.Header>
                            <Accordion.Body>
                              Experience the thrill of racehorse ownership.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="1"
                            onClick={() => bespokeApp('curated_events')}
                            className={`save_action ${
                              bespokeAppData === 'curated_events' ? 'active' : 'inactive'
                            }`}
                          >
                            <Accordion.Header>
                              Curated Events
                              <span className="action_icon plus_icon">
                                <PlusIconSvg />
                              </span>
                              <span className="action_icon minus_icon">
                                <MinusIconSvg />
                              </span>
                            </Accordion.Header>
                            <Accordion.Body>
                              Access a calendar of iconic events from around the world.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="2"
                            onClick={() => bespokeApp('equine_concierge')}
                            className={`save_action ${
                              bespokeAppData === 'equine_concierge' ? 'active' : 'inactive'
                            }`}
                          >
                            <Accordion.Header>
                              Equine Concierge
                              <span className="action_icon plus_icon">
                                <PlusIconSvg />
                              </span>
                              <span className="action_icon minus_icon">
                                <MinusIconSvg />
                              </span>
                            </Accordion.Header>
                            <Accordion.Body>
                              Exclusivity in racehorse experiences and enhanced management for all
                              owners.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="3"
                            onClick={() => bespokeApp('sports_data')}
                            className={`save_action ${
                              bespokeAppData === 'sports_data' ? 'active' : 'inactive'
                            }`}
                          >
                            <Accordion.Header>
                              Sports Data
                              <span className="action_icon plus_icon">
                                <PlusIconSvg />
                              </span>
                              <span className="action_icon minus_icon">
                                <MinusIconSvg />
                              </span>
                            </Accordion.Header>
                            <Accordion.Body>
                              Gain a sophisticated and intelligent advantage within the world of
                              racing
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <div
                        className={`stable_image ${
                          bespokeAppData === 'my_stable' ? 'd-block' : 'd-none'
                        }`}
                      >
                        <img
                          src="https://static-web.fra1.digitaloceanspaces.com/royalarion/my-stable-app-img.png"
                          alt=""
                        />
                      </div>
                      <div
                        className={`stable_image ${
                          bespokeAppData === 'curated_events' ? 'd-block' : 'd-none'
                        }`}
                      >
                        <img
                          src="https://static-web.fra1.digitaloceanspaces.com/royalarion/currated-events-app-img.png"
                          alt=""
                        />
                      </div>
                      <div
                        className={`stable_image ${
                          bespokeAppData === 'equine_concierge' ? 'd-block' : 'd-none'
                        }`}
                      >
                        <img
                          src="https://static-web.fra1.digitaloceanspaces.com/royalarion/equine-concierge-app-img.png"
                          alt=""
                        />
                      </div>
                      <div
                        className={`stable_image ${
                          bespokeAppData === 'sports_data' ? 'd-block' : 'd-none'
                        }`}
                      >
                        <img
                          src="https://static-web.fra1.digitaloceanspaces.com/royalarion/sports-data-app-img.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="experience_left_content right_space col-lg-4 col-md-4 col-12">
                      <div
                        className={`cards_wrapper ${
                          bespokeAppData === 'my_stable' ? 'd-block' : 'd-none'
                        }`}
                      >
                        <div className="cards_list">
                          <div className="cards_container">
                            <div className="icon_wrap">
                              <img
                                src="https://static-web.fra1.digitaloceanspaces.com/royalarion/headphone-line-icon.svg"
                                alt=""
                              />
                            </div>
                            <p>Pre and post race commentary videos directly from the trainers.</p>
                          </div>
                        </div>
                        <div className="cards_list">
                          <div className="cards_container">
                            <div className="icon_wrap">
                              <img
                                src="https://static-web.fra1.digitaloceanspaces.com/royalarion/video-line-icon.svg"
                                alt=""
                              />
                            </div>
                            <p>Video updates from trainers, vets, farriers & jockeys.</p>
                          </div>
                        </div>
                        <div className="cards_list">
                          <div className="cards_container">
                            <div className="icon_wrap">
                              <img
                                src="https://static-web.fra1.digitaloceanspaces.com/royalarion/server-line-icon.svg"
                                alt=""
                              />
                            </div>
                            <p>
                              Access valuable data on the progress of a horses training from our
                              high-tech training facilities.
                            </p>
                          </div>
                        </div>
                        <div className="cards_list">
                          <div className="cards_container">
                            <div className="icon_wrap">
                              <img
                                src="https://static-web.fra1.digitaloceanspaces.com/royalarion/star-line-icon.svg"
                                alt=""
                              />
                            </div>
                            <p>
                              Visit the horses at the trainers stables, meet the trainers and get up
                              close to the horses.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`cards_wrapper ${
                          bespokeAppData === 'curated_events' ? 'd-block' : 'd-none'
                        }`}
                      >
                        <div className="cards_list">
                          <div className="cards_container">
                            <div className="icon_wrap">
                              <img
                                src="https://static-web.fra1.digitaloceanspaces.com/royalarion/calendar-line-icon.svg"
                                alt=""
                              />
                            </div>
                            <p>Attend exclusive members invitation only events globally.</p>
                          </div>
                        </div>
                        <div className="cards_list">
                          <div className="cards_container">
                            <div className="icon_wrap">
                              <img
                                src="https://static-web.fra1.digitaloceanspaces.com/royalarion/tv-line-icon.svg"
                                alt=""
                              />
                            </div>
                            <p>
                              Access a curated world class lifestyle editorial from our luxury
                              partners.
                            </p>
                          </div>
                        </div>
                        <div className="cards_list">
                          <div className="cards_container">
                            <div className="icon_wrap">
                              <img
                                src="https://static-web.fra1.digitaloceanspaces.com/royalarion/suitcase-line-icon.svg"
                                alt=""
                              />
                            </div>
                            <p>
                              Interact, network and share your passion with other successful
                              individuals within our eco-system.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`cards_wrapper ${
                          bespokeAppData === 'equine_concierge' ? 'd-block' : 'd-none'
                        }`}
                      >
                        <div className="cards_list">
                          <div className="cards_container">
                            <div className="icon_wrap">
                              <img
                                src="https://static-web.fra1.digitaloceanspaces.com/royalarion/ph-horse-icon.svg"
                                alt=""
                              />
                            </div>
                            <p>
                              Get guidance and support in acquiring the perfect equine athlete at
                              auctions.
                            </p>
                          </div>
                        </div>
                        <div className="cards_list">
                          <div className="cards_container">
                            <div className="icon_wrap">
                              <img
                                src="https://static-web.fra1.digitaloceanspaces.com/royalarion/device-line-icon.svg"
                                alt=""
                              />
                            </div>
                            <p>
                              Our racehorse management service will enhance the existing ownership
                              experience, with regular updates, video content and data on their
                              horses.
                            </p>
                          </div>
                        </div>
                        <div className="cards_list">
                          <div className="cards_container">
                            <div className="icon_wrap">
                              <img
                                src="https://static-web.fra1.digitaloceanspaces.com/royalarion/group-2-line-icon.svg"
                                alt=""
                              />
                            </div>
                            <p>
                              We help passionate equestrians, form a syndicate and help them become
                              more deeply involved in the world of racing.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`cards_wrapper ${
                          bespokeAppData === 'sports_data' ? 'd-block' : 'd-none'
                        }`}
                      >
                        <div className="cards_list">
                          <div className="cards_container">
                            <div className="icon_wrap">
                              <img
                                src="https://static-web.fra1.digitaloceanspaces.com/royalarion/play-circle-line.svg"
                                alt=""
                              />
                            </div>
                            <p>Watch live races from around the world, live on the app.</p>
                          </div>
                        </div>
                        <div className="cards_list">
                          <div className="cards_container">
                            <div className="icon_wrap">
                              <img
                                src="https://static-web.fra1.digitaloceanspaces.com/royalarion/grid-line-icon.svg"
                                alt=""
                              />
                            </div>
                            <p>Race form guides, Global tipping service, Gallop/work out data.</p>
                          </div>
                        </div>
                        <div className="cards_list">
                          <div className="cards_container">
                            <div className="icon_wrap">
                              <img
                                src="https://static-web.fra1.digitaloceanspaces.com/royalarion/user-voice-line-icon.svg"
                                alt=""
                              />
                            </div>
                            <p>International horse rating updates and commentary.</p>
                          </div>
                        </div>
                        <div className="cards_list">
                          <div className="cards_container">
                            <div className="icon_wrap">
                              <img
                                src="https://static-web.fra1.digitaloceanspaces.com/royalarion/bar-chart-line-icon.svg"
                                alt=""
                              />
                            </div>
                            <p>
                              Pre and post race analysis, Pedigree analysis, Sibling sales updates.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* start of app experience container */}
        {/* start of types of membership container */}
        <div className="types_of_membership_container">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="types_of_membership_content full_width">
                  <div className="heading">
                    <h4>Membership Plans</h4>
                  </div>
                  <div className="membership_tabs_content full_width">
                    <Tabs defaultActiveKey="digital" id="uncontrolled-tab-example">
                      <Tab eventKey="digital" title="DIGITAL">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="member_tab_left_content full_width">
                              <h5>
                                DIGITAL
                                <br />
                                MEMBERSHIP
                              </h5>
                              <p>
                                <strong>£20</strong>
                                <small>/Month</small>
                                <strong>£216</strong>
                                <small>/Year</small>
                                <span>10% off</span>
                              </p>
                              <p className="no_br_tp">
                                <strong>₹2100</strong>
                                <small>/Month</small>
                                <strong>₹22,500</strong>
                                <small>/Year</small>
                                <span>10% off</span>
                              </p>
                              <Link to="/signup">Sign up</Link>
                            </div>
                          </div>
                          <div className="col-lg-9">
                            <div className="member_tab_right_content full_width">
                              <h6>MEMBERSHIP BENEFITS</h6>
                              <ul>
                                <li>
                                  Own a 0.1% <b>club share</b> in one specified horse in India or
                                  UK, as chosen by the member.
                                </li>
                                <li>Share of the prize money won by the specified horse.</li>
                                <li>Follow all Royal Arion Club horses through our App.</li>
                                <li>
                                  Watch live international racing in app (for members based in
                                  India)
                                </li>
                                <li>
                                  Advanced racing data, including race cards, trainer, jockey, vet,
                                  and industry professional reports for the specified horse.
                                </li>
                                <li>Attend races when your horse runs.</li>
                                <li>
                                  Visits to see the specified horse in training and meet the
                                  trainers on pre-determined dates throughout the year.
                                </li>
                                <li>
                                  Enter the Club’s regular raffles, and win extraordinary racing and
                                  luxury experiences
                                </li>
                              </ul>
                              <div className="fractional_ownership">
                                <h4>OWN A SHARE IN ONE OF THE FOLLOWING</h4>
                              </div>
                              <div className="row stable_list">
                                <div className="col-6">
                                  <div className="card_container">
                                    <div className="img_wrap">
                                      <img
                                        src="https://static-web.fra1.digitaloceanspaces.com/royalarion/fascinating-rock-thumb-img.jpg"
                                        alt=""
                                        width={180}
                                        height={180}
                                      />
                                    </div>
                                    <div className="stable_card_content">
                                      <img
                                        src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/uk-round-flag.svg"
                                        alt=""
                                        width={17}
                                        height={17}
                                      />
                                      <h5>Rock and Royal</h5>
                                      <div className="horse_details_info full_width">
                                        <label>Sire / Dam</label>
                                        <p>Fascinating Rock (IRE) / Spesialta (GB)</p>
                                      </div>
                                      <div className="horse_details_info horse_desc_info mb-0">
                                        <label>Desc.</label>
                                        <p>3yo Bay Filly</p>
                                      </div>
                                      <div className="horse_details_info trainer_detl_info mb-0">
                                        <label>Trainer</label>
                                        <p>Dylan Cunha</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="card_container">
                                    <div className="img_wrap">
                                      <img
                                        src="https://static-web.fra1.digitaloceanspaces.com/royalarion/chinese-whisper-royal-thumb-img.jpg"
                                        alt=""
                                        width={180}
                                        height={180}
                                      />
                                    </div>
                                    <div className="stable_card_content">
                                      <img
                                        src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-round-flag.svg"
                                        alt=""
                                        width={17}
                                        height={17}
                                      />
                                      <h5>Royal Whisper</h5>
                                      <div className="horse_details_info full_width">
                                        <label>Sire / Dam</label>
                                        <p>Chinese Whisper / Royal Superlative</p>
                                      </div>
                                      <div className="horse_details_info horse_desc_info mb-0">
                                        <label>Desc.</label>
                                        <p>3yo Bay Filly</p>
                                      </div>
                                      <div className="horse_details_info trainer_detl_info mb-0">
                                        <label>Trainer</label>
                                        <p>Neil Devaney</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="card_container">
                                    <div className="img_wrap">
                                      <img
                                        src="https://static-web.fra1.digitaloceanspaces.com/royalarion/eloquent-thumb-img.jpg"
                                        alt=""
                                        width={180}
                                        height={180}
                                      />
                                    </div>
                                    <div className="stable_card_content">
                                      <img
                                        src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-round-flag.svg"
                                        alt=""
                                        width={17}
                                        height={17}
                                      />
                                      <h5>Eloquent</h5>
                                      <div className="horse_details_info full_width">
                                        <label>Sire / Dam</label>
                                        <p>Speaking of Which / Auntie Kathryn</p>
                                      </div>
                                      <div className="horse_details_info horse_desc_info mb-0">
                                        <label>Desc.</label>
                                        <p>4yo Bay Gelding</p>
                                      </div>
                                      <div className="horse_details_info trainer_detl_info mb-0">
                                        <label>Trainer</label>
                                        <p>Shazaan Shah</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="club" title="CLUB">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="member_tab_left_content full_width">
                              <h5>
                                CLUB
                                <br />
                                MEMBERSHIP
                              </h5>
                              <p>
                                <strong>£2500</strong>
                                <small>/Year</small>
                              </p>
                              <p className="no_br_tp">
                                <strong>₹255,000</strong>
                                <small>/Year</small>
                              </p>
                              <Link to="/signup">Sign up</Link>
                            </div>
                          </div>
                          <div className="col-lg-9">
                            <div className="member_tab_right_content full_width">
                              <h6>MEMBERSHIP BENEFITS</h6>
                              <ul>
                                <li>
                                  Own club shares in all Club horses in training (currently 2 in the
                                  UK &amp; 4 in India) whilst being treated as they are your very
                                  own. Follow all 8 Royal Arion horses via our interactive app.
                                </li>
                                <li>Share of prize money won by the Club horses.</li>
                                <li>
                                  Attend the races with owners and trainers access. Stable and
                                  gallops visits to see horses and meet the trainers.
                                </li>
                                <li>Racing industry editorial.</li>
                                <li>
                                  Watch live international racing in app (for members based in
                                  India)
                                </li>
                                <li>
                                  Advanced racing data, including race cards, trainer, jockey, vet,
                                  and industry professional reports for the specified horse.
                                </li>
                                <li>
                                  Access to all Club services and privileges, including social &amp;
                                  networking events
                                </li>
                                <li>
                                  Enjoy benefits, offers, editorial and entertainment from our
                                  Luxury Brand partners.
                                </li>
                                <li>
                                  Invitations to exclusive events in the UK, India, and beyond.
                                </li>
                                <li>
                                  Enter the Club’s regular raffles, and win extraordinary global
                                  elite racing and luxury experiences
                                </li>
                              </ul>
                              <div className="fractional_ownership">
                                <h4>OWN CLUB SHARES IN ALL OF THE FOLLOWING:</h4>
                              </div>
                              <div className="row stable_list">
                                <div className="col-6">
                                  <div className="card_container">
                                    <div className="img_wrap">
                                      <img
                                        src="https://static-web.fra1.digitaloceanspaces.com/royalarion/sergei-thumb-img.jpg"
                                        alt=""
                                        width={180}
                                        height={180}
                                      />
                                    </div>
                                    <div className="stable_card_content">
                                      <img
                                        src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/uk-round-flag.svg"
                                        alt=""
                                        width={17}
                                        height={17}
                                      />
                                      <h5>Sergei Prokofiev x Charline Royale</h5>
                                      <div className="horse_details_info full_width">
                                        <label>Sire / Dam</label>
                                        <p>Sergei Prokofiev /Charline Royale (Ire)</p>
                                      </div>
                                      <div className="horse_details_info horse_desc_info mb-0">
                                        <label>Desc.</label>
                                        <p>2yo Bay Filly</p>
                                      </div>
                                      <div className="horse_details_info trainer_detl_info mb-0">
                                        <label>Trainer</label>
                                        <p>Andrew Balding</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="card_container">
                                    <div className="img_wrap">
                                      <img
                                        src="https://static-web.fra1.digitaloceanspaces.com/royalarion/coeur-de-lion-thumb-img.jpg"
                                        alt=""
                                        width={180}
                                        height={180}
                                      />
                                    </div>
                                    <div className="stable_card_content">
                                      <img
                                        src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-round-flag.svg"
                                        alt=""
                                        width={17}
                                        height={17}
                                      />
                                      <h5>Coeur De Lion</h5>
                                      <div className="horse_details_info full_width">
                                        <label>Sire / Dam</label>
                                        <p>Roderic O'Connor[IRE] / Sweeping Star</p>
                                      </div>
                                      <div className="horse_details_info horse_desc_info mb-0">
                                        <label>Desc.</label>
                                        <p>5yo Bay Colt</p>
                                      </div>
                                      <div className="horse_details_info trainer_detl_info mb-0">
                                        <label>Trainer</label>
                                        <p>Narendra Lagad</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row stable_list">
                                <div className="col-6">
                                  <div className="card_container">
                                    <div className="img_wrap">
                                      <img
                                        src="https://static-web.fra1.digitaloceanspaces.com/royalarion/leitir-thumb-img.jpg"
                                        alt=""
                                        width={180}
                                        height={180}
                                      />
                                    </div>
                                    <div className="stable_card_content">
                                      <img
                                        src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-round-flag.svg"
                                        alt=""
                                        width={17}
                                        height={17}
                                      />
                                      <h5>Leitir Mor x Stravella</h5>
                                      <div className="horse_details_info full_width">
                                        <label>Sire / Dam</label>
                                        <p>Stravella (IRE) / Leitir Mor</p>
                                      </div>
                                      <div className="horse_details_info horse_desc_info mb-0">
                                        <label>Desc.</label>
                                        <p>3yo Bay Filly</p>
                                      </div>
                                      <div className="horse_details_info trainer_detl_info mb-0">
                                        <label>Trainer</label>
                                        <p>Hilton Sequera</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="card_container">
                                    <div className="img_wrap">
                                      <img
                                        src="https://static-web.fra1.digitaloceanspaces.com/royalarion/showcasing-thumb-img.jpg"
                                        alt=""
                                        width={180}
                                        height={180}
                                      />
                                    </div>
                                    <div className="stable_card_content">
                                      <img
                                        src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/uk-round-flag.svg"
                                        alt=""
                                        width={17}
                                        height={17}
                                      />
                                      <h5>Royal Elysian</h5>
                                      <div className="horse_details_info full_width">
                                        <label>Sire / Dam</label>
                                        <p>Showcasing (GB) / Chloris (GB)</p>
                                      </div>
                                      <div className="horse_details_info horse_desc_info mb-0">
                                        <label>Desc.</label>
                                        <p>3yo Bay Filly</p>
                                      </div>
                                      <div className="horse_details_info trainer_detl_info mb-0">
                                        <label>Trainer</label>
                                        <p>James Ferguson</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row stable_list">
                                <div className="col-6">
                                  <div className="card_container">
                                    <div className="img_wrap">
                                      <img
                                        src="https://static-web.fra1.digitaloceanspaces.com/royalarion/gusto-thumb-img.jpg"
                                        alt=""
                                        width={180}
                                        height={180}
                                      />
                                    </div>
                                    <div className="stable_card_content">
                                      <img
                                        src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-round-flag.svg"
                                        alt=""
                                        width={17}
                                        height={17}
                                      />
                                      <h5>Royal Decan</h5>
                                      <div className="horse_details_info full_width">
                                        <label>Sire / Dam</label>
                                        <p>Gusto (GB) / Fearless Nadia</p>
                                      </div>
                                      <div className="horse_details_info horse_desc_info mb-0">
                                        <label>Desc.</label>
                                        <p>3yo Bay Colt</p>
                                      </div>
                                      <div className="horse_details_info trainer_detl_info mb-0">
                                        <label>Trainer</label>
                                        <p>Arjun Mangalorkar</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="card_container">
                                    <div className="img_wrap">
                                      <img
                                        src="https://static-web.fra1.digitaloceanspaces.com/royalarion/chinese-whisper-charm-thumb-img.jpg"
                                        alt=""
                                        width={180}
                                        height={180}
                                      />
                                    </div>
                                    <div className="stable_card_content">
                                      <img
                                        src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/ind-round-flag.svg"
                                        alt=""
                                        width={17}
                                        height={17}
                                      />
                                      <h5>Emperors Charm</h5>
                                      <div className="horse_details_info full_width">
                                        <label>Sire / Dam</label>
                                        <p>Chinese Whisper(IRE) / So Charm (IRE)</p>
                                      </div>
                                      <div className="horse_details_info horse_desc_info mb-0">
                                        <label>Desc.</label>
                                        <p>3yo Bay Colt</p>
                                      </div>
                                      <div className="horse_details_info trainer_detl_info mb-0">
                                        <label>Trainer</label>
                                        <p>Hilton Sequera</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of types of membership container */}
        {/* start of partner container */}
        <div className="partner_container bottom_partner_container">
          <div className="container-fluid">
            <div className="row">
              <div className="partner_content col-12">
                <h2>Our Brand Partners</h2>
                <div className="partner_slider">
                  <ul>
                    <li>
                      <Link to="/">
                        <img
                          src="https://static-web.fra1.digitaloceanspaces.com/royalarion/boodles-logo-dark.svg"
                          alt=""
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <img
                          src="https://static-web.fra1.digitaloceanspaces.com/royalarion/taj-logo-dark.svg"
                          alt=""
                        />
                      </Link>
                    </li>
                    {/* <li>
                    <Link to="/">
                      <img
                        src="https://static-web.fra1.digitaloceanspaces.com/royalarion/huntsman-logo-dark.svg"
                        alt="" />
                    </Link>
                  </li> */}
                    <li>
                      <Link to="/">
                        <img
                          src="https://static-web.fra1.digitaloceanspaces.com/royalarion/abercrombie-and-kent-logo-dark.svg"
                          alt=""
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <img
                          src="https://static-web.fra1.digitaloceanspaces.com/royalarion/kilchoman-logo-dark.svg"
                          alt=""
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <img
                          src="https://static-web.fra1.digitaloceanspaces.com/royalarion/icmi-logo-dark.svg"
                          alt=""
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <img
                          src="https://static-web.fra1.digitaloceanspaces.com/royalarion/tjb-superyachts-logo-dark.svg"
                          alt=""
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <img
                          src="https://static-web.fra1.digitaloceanspaces.com/royalarion/boodles-logo-dark.svg"
                          alt=""
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <img
                          src="https://static-web.fra1.digitaloceanspaces.com/royalarion/taj-logo-dark.svg"
                          alt=""
                        />
                      </Link>
                    </li>
                    {/* <li>
                    <Link to="/">
                      <img
                        src="https://static-web.fra1.digitaloceanspaces.com/royalarion/huntsman-logo-dark.svg"
                        alt="" />
                    </Link>
                  </li> */}
                    <li>
                      <Link to="/">
                        <img
                          src="https://static-web.fra1.digitaloceanspaces.com/royalarion/abercrombie-and-kent-logo-dark.svg"
                          alt=""
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <img
                          src="https://static-web.fra1.digitaloceanspaces.com/royalarion/kilchoman-logo-dark.svg"
                          alt=""
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <img
                          src="https://static-web.fra1.digitaloceanspaces.com/royalarion/icmi-logo-dark.svg"
                          alt=""
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <img
                          src="https://static-web.fra1.digitaloceanspaces.com/royalarion/tjb-superyachts-logo-dark.svg"
                          alt=""
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of partner container */}
        {/* start of faq container */}
        <div className="faq_container faqpaddtop">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="faq_heading">
                  <h3 className="text-gradient">Frequently Asked Questions </h3>
                  <p>
                    We trust that the information provided in this section has addressed your
                    inquiries. If you have any further questions or need additional assistance,
                    please consider visiting our <Link to="/contact">'Contact'</Link> page for
                    further guidance.
                  </p>
                </div>
                <div className="faq_content">
                  <div className="faq_accordion">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          What can I expect from Club membership?
                          <span className="action_icon plus_icon">
                            <PlusIconSvg />
                          </span>
                          <span className="action_icon minus_icon">
                            <MinusIconSvg />
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>
                              Access to all Royal Arion Club horses through our enhanced Ownership
                              Experience.To include visits to see the horses in training and meet
                              the trainers at their yards.
                            </li>
                            <li>
                              The Royal Arion Club will purchase or lease the Club horses. When they
                              join, members are given club shares in all horses in training whilst
                              being treated as if they were your very own
                            </li>
                            <li>An equal share of the prize money won by the Club horses</li>
                            <li>Racing industry editorial</li>
                            <li>
                              Members based in India can watch live International racing for free
                              and Indian racing for additional charge (same rate as is currently
                              charged at Turf Authorities)
                            </li>
                            <li>
                              Advanced racing data, including race cards and relevant racing
                              information
                            </li>
                            <li>Access to all Royal Arion Club services and privileges</li>
                            <li>Access to benefits and offers from our Luxury Brand partners</li>
                            <li>Invitations to exclusive events in the UK, India and beyond</li>
                            <li>
                              Access to our highly experienced racing managers, through our Equine
                              Concierge Service
                            </li>
                            <li>Founders Card Membership</li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          What is the 'Ownership Experience' for Club members?
                          <span className="action_icon plus_icon">
                            <PlusIconSvg />
                          </span>
                          <span className="action_icon minus_icon">
                            <MinusIconSvg />
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>
                              As a Club Member you will receive the highest level of personal
                              service from our Racing Managers. Each member is treated as if he or
                              she own their horses outright.
                            </li>
                            <li>
                              Members will own club shares in all club horses in training (currently
                              6)
                            </li>
                            <li>
                              As such you will be treated as a racehorse owner. In fact, through our
                              Ownership Experience, you will receive an enhanced level of
                              information, service, interaction and involvement with the horses,
                              trainers and jockeys
                            </li>
                            <li>
                              Regular updates on all the horses via the Club App, created by our
                              inhouse team of videographers and editors
                            </li>
                            <li>
                              Stable visits arranged to watch your horses work on the gallops and
                              meet the trainers
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          What can I expect from a Digital membership?
                          <span className="action_icon plus_icon">
                            <PlusIconSvg />
                          </span>
                          <span className="action_icon minus_icon">
                            <MinusIconSvg />
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>
                              Access to all the Royal Arion Club horses through our web based App
                            </li>
                            <li>
                              A 0.1% club share in one of our specified horses in India or UK, as
                              chosen by the member
                            </li>
                            <li>An equal share of the prize money that your horse wins</li>
                            <li>
                              Members based in India can watch live International racing for free
                              and Indian racing for additional charge (same rate as is currently
                              charged at Turf Authorities)
                            </li>
                            <li>
                              Visits to see the horses in training and meet the trainers at their
                              yards, on pre-determined dates throughout the year
                            </li>
                            <li>Racing industry editorial</li>
                            <li>Luxury partner editorial</li>
                            <li>
                              Advanced racing data, including race cards and relevant racing
                              information
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          Do I receive prize money ?
                          <span className="action_icon plus_icon">
                            <PlusIconSvg />
                          </span>
                          <span className="action_icon minus_icon">
                            <MinusIconSvg />
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>
                              Prize money will be paid as a dividend to shareholders at the end of
                              each calendar year (pro rata based on joining date). Club members will
                              receive prize money from all Club horses internationally. Digital
                              members from their 1 horse.
                            </li>
                            <li>The Royal Arion Club will retain 10% of this prize money</li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>
                          What events might I be invited to as a Club Member?
                          <span className="action_icon plus_icon">
                            <PlusIconSvg />
                          </span>
                          <span className="action_icon minus_icon">
                            <MinusIconSvg />
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>
                              Through our calendar of events and in conjunction with our brand
                              partners, Club members will be invited to an array of racing, sporting
                              and social events
                            </li>
                            <li>
                              These will range from attending Royal Ascot, The Saudi Cup, The Indian
                              Derby The Melbourne Cup and other international racing events
                            </li>
                            <li>
                              Our calendar also includes an array of exciting sporting events , such
                              as the Monaco Grand Prix Superyacht event, the final of the IPL
                              cricket or the final of the French Open Tennis.
                            </li>
                            <li>
                              In the cities where our horses are in training you will be invited to
                              regular gallops / stable visits, race events, social and networking
                              opportunities
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header>
                          Will I have the opportunity to interact with other members ?
                          <span className="action_icon plus_icon">
                            <PlusIconSvg />
                          </span>
                          <span className="action_icon minus_icon">
                            <MinusIconSvg />
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li>
                              One of the benefits of being a Club member is to have the opportunity
                              to socialise and network with other members, either at the races or at
                              one of our many events
                            </li>
                            <li>
                              Through our members and partners we will build a global network of
                              likeminded individuals
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  <div className="faq_link text-center">
                    <Link to="/contact/faq">View All</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of faq container */}
      </div>
    </>
  );
}

export default Home;