import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className="full_block">
      {/* start of banner container */}
      <div className="banner_container pagenot_found_container">
        <div className="container">
          <div className="row header_row">
            <div className="circle-ripple"></div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="header_wrapper">
                <div className="logo_wrap">
                    <Link to="/">
                        <img src="https://static-web.fra1.digitaloceanspaces.com/royalarion/ra-logo.svg" width="291" height="52" alt="" />
                    </Link>
                </div>
              </div>
              <div className="banner_content pagenot_found">
                <h2>404</h2>
                <h3>Page Not Found</h3>
                <p>This page doesn't exist or was removed!</p>
              </div>
            </div>
            <div className='blur_circle'></div>
          </div>
        </div>
      </div>
      {/* end of banner container */}
    </div>
  );
}

export default NotFound;