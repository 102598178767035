// import http from './http-common';
import { post, get, put } from 'components/_Services/HttpService';
import http from './http-common';
const BASE_URL = process.env.REACT_APP_AUTHENTICATION_API_URL;

const RACING_URL = 'https://node.rwitc.com:3002/';
const SIS_URL =
  'https://zwhrwwps22tcpnlu4u7vlzv3qi0lgyvy.lambda-url.ap-southeast-1.on.aws/admin/sis/streams';
// const RACING_URL = 'https://h74qf6qi6eegeciorqgi6dpmmy0rjnye.lambda-url.ap-southeast-1.on.aws/';

export const getVenuesData = async () => {
  // const response = await get(`${RACING_URL}events/active`);
  const response = await get(`${RACING_URL}data/racing.json`);
  return response;
  // .then(
  //   (response: any) => ({
  //     ...response,
  //     statusCode: response.status,
  //   }),
  //   // return response.data;
  // )
  // .catch((e: any) => ({
  //   message: e.response?.data.message || e.message,
  //   errors: e.response?.data?.errorCode,
  //   statusCode: e.response.status,
  //   status: e.response?.data.status,
  // }));
};
export const getSisStreamData = async () => {
  // const response = await get(`${RACING_URL}events/active`);
  const response = await get(`${SIS_URL}`);
  return response;
};
export const addSisStreamData = async (data) => {
  // const response = await get(`${RACING_URL}events/active`);
  const response = await post(`${BASE_URL}/admin/stream/add`, data);
  return response;
};
export const getRaceCardDetails = async (raceId: any) => {
  const response = await get(`${RACING_URL}racecard/${raceId}`);
  // .then(
  //   (response: any) => ({
  //     ...response,
  //     statusCode: response.status,
  //   }),
  //   // return response.data;
  // )
  // .catch((e: any) => ({
  //   message: e.response?.data.message || e.message,
  //   errors: e.response?.data?.errorCode,
  //   statusCode: e.response.status,
  //   status: e.response?.data.status,
  // }));
  return response.body;
};
// export const getVenuesData = async () => {
//   const token = localStorage.getItem('access_token');
//   const headers = {
//     'Content-Type': 'application/json',
//     Authorization: `Bearer ${token}`,
//   };

//   return http
//     .get('/events/active', { headers })
//     .then(
//       (response: any) => ({
//         ...response,
//         statusCode: response.status,
//       }),
//       // return response.data;
//     )
//     .catch((e: any) => ({
//       message: e.response?.data.message || e.message,
//       errors: e.response?.data?.errorCode,
//       statusCode: e.response.status,
//       status: e.response?.data.status,
//     }));
// };
// export const getRaceCardDetails = async (raceId: any) => {
//   const token = localStorage.getItem('access_token');
//   const headers = {
//     'Content-Type': 'application/json',
//     Authorization: `Bearer ${token}`,
//   };

//   return http
//     .get(`/racecard/${raceId}`, { headers })
//     .then(
//       (response: any) => ({
//         ...response.data.body,
//         statusCode: response.status,
//       }),
//       // return response.data;
//     )
//     .catch((e: any) => ({
//       message: e.response?.data.message || e.message,
//       errors: e.response?.data?.errorCode,
//       statusCode: e.response.status,
//       status: e.response?.data.status,
//     }));
// };
