import React from 'react';

export function AlertMessage({ type, message }: { type: string; message: string }) {
  return (
    <div className={`alert alert-${type}`} role="alert">
      <img
        className="error_fill_icon"
        src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/error-fill-icon.svg"
        width={24}
        height={24}
        alt=""
      />
      <img
        className="success_fill_icon"
        src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/success-fill-icon.svg"
        width={24}
        height={24}
        alt=""
      />
      <p>{message}</p>
    </div>
  );
}
