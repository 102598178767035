import React, { Component } from 'react';
import ReactCardCarousel from 'react-card-carousel';

 const MyCarousel = () =>{

return (
      <ReactCardCarousel className='landingright' initial_index={0} autoplay={true} autoplay_speed={6000} disable_box_shadow={true} disable_fade_in={true}>
        <div className='cardcarousel'>
          <img src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/luxury-partner-slide-img.png" alt="" />
          <div className='cardcarousel_text'>
            <h5>Luxury Partner Benefits</h5>
            <p>Club Members get exclusive perks and a 12-month Founders Card membership included.</p>
          </div>
        </div>
        <div className='cardcarousel'>
          <img src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/equine-concierge-slide-img.png" alt="" />
          <div className='cardcarousel_text'>
            <h5>Equine Concierge</h5>
            <p>Our members will also have access to world class racing data to gain a sophisticated and intelligent advantage within the world of racing.</p>
          </div>
        </div>
        <div className='cardcarousel'>
          <img src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/racehorse-slide-img.png" alt="" />
          <div className='cardcarousel_text'>
            <h5>Racehorse Ownership Experience</h5>
            <p>Join our thrilling racehorse ownership program, following 10 Royal Arion thoroughbreds in training in India and the UK. Experience the excitement firsthand!</p>
          </div>
        </div>
        <div className='cardcarousel'>
          <img src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/luxury-lifestyle-slide-img.png" alt="" />
          <div className='cardcarousel_text'>
            <h5>Luxury Lifestyle Editorial and Events</h5>
            <p>We deliver bespoke luxury content following a calendar of iconic sporting and social events. A world-class luxury lifestyle editorial for every member.</p>
          </div>
        </div>
      </ReactCardCarousel>
    );
  
}

export default MyCarousel;