import React from 'react';
import { Route, Navigate , RouteProps} from 'react-router-dom';
import { connect } from "react-redux";

function PrivateRoute({ children}) {

    const userinfo = localStorage.getItem("userInfo");
    const userInfoData = JSON.parse(userinfo);
    
    return (
        <>
        {userinfo ? <>{children}</> : <Navigate to="/" />}
        </>
    );
}


export default PrivateRoute;
//export default PrivateRoute ;