import React from 'react';
import './Login.css';
import { Link } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { addUser } from '../../services/user';
import { useState } from 'react';

enum UserStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum SignupActivationdSteps {
  SIGN_UP = 'SIGN_UP',
  LOGIN = 'LOGIN',
  ENTER_OTP = 'ENTER_OTP',
  ENTER_OTP_ERROR = 'ENTER_OTP_ERROR',
  ENTER_OTP_SUCCESS = 'ENTER_OTP_SUCCESS',
}

function SignUpVerificationSuccess({ doStep }: { doStep: Function }) {
  const [message, setMessage] = useState('');
  const [loadSubmit, setLoadSubmit] = useState(false);
  const [status, setStatus] = useState<UserStatus | null>(null);

  // const { register, handleSubmit,reset,formState: { errors } } = useForm<Inputs> ({});

  // const onSubmit: SubmitHandler<Inputs> = async (data) => {
  //   setLoadSubmit(true);
  //   data.full_name = data.firstName+' '+data.lastName;
  //   const userData = {full_name:data.full_name,user_email:data.user_email,password:data.password};

  //   const {
  //     status,
  //     message: message,
  //     errors: err,
  //     statusCode,
  //   } = await addUser(userData);

  //   setLoadSubmit(false);
  //   setStatus(status);
  //   setMessage(message);

  //   console.log(status);
  //   if (status === UserStatus.SUCCESS) {

  //     reset();
  //     doStep(SignupActivationdSteps.ENTER_OTP_SUCCESS);
  //   }else{
  //     doStep(SignupActivationdSteps.ENTER_OTP_ERROR);
  //   }

  // };
  const getLogin = () => {
    doStep(SignupActivationdSteps.LOGIN);
  };

  return (
    <div className="full_width">
      <div className="password_ripple">
        <img src="/images/forgot-password-ripple-bg.png" alt="" />
      </div>
      <div className="password_blur"></div>
      <div className="middle_container">
        <div className="form_container password_form_container">
          <form id="contact-form1" className="get-a-quote" autoComplete="off">
            <div className="text-center">
              <div className="heading_content">
                <img src="/images/email-verify-icon.svg" alt="" width="56" height="56" />
                <h2 className="text-gradient">Email verified</h2>
                <p className="p-0">
                  Your email has been successfully verified. Click below to proceed.
                </p>
              </div>
            </div>
            <div className="back_login_link full_width">
              <Link to="/login" onClick={() => getLogin()}>
                <img src="/images/arrow-left-icon.svg" alt="" width="20" height="20" />
                Back to Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUpVerificationSuccess;
