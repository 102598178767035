import { createSlice } from '@reduxjs/toolkit';
interface IAuthInfo {
  id: number;
  token: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}
interface IAuthentication {
  userInfo?: IAuthInfo;
  isAuthenticated: boolean;
}
const initialState: IAuthentication = {
  userInfo: undefined,
  isAuthenticated: false,
}

const authenticationSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUserData(state, action) {
      state.userInfo = action.payload;
    },
    setIsAuthenticatedData(state, action) {
      if (action.payload != undefined) state.isAuthenticated = action.payload;
    },
  }
});

export const {
  setUserData,
  setIsAuthenticatedData
} = authenticationSlice.actions;
export const authenticationReducer = authenticationSlice.reducer
