import axios from 'axios';

export default axios.create({
  // baseURL: 'https://h74qf6qi6eegeciorqgi6dpmmy0rjnye.lambda-url.ap-southeast-1.on.aws',
  // development
  // baseURL: 'https://eeacb7tqdhyz6ecpeyqwpip2jq0tdpcn.lambda-url.eu-west-2.on.aws',
  // production
  baseURL: 'https://6ags7buu6hmrnp3sb77zs6edae0zvxgd.lambda-url.eu-west-2.on.aws',
  // baseURL: 'https://royalarion.atnoc.com/login-api/',
  // baseURL: "https://royalarion.com/login-api/",
  // baseURL: "http://localhost:3001/",
  // BASE_URL: '',
  headers: {
    'Content-type': 'application/json',
  },
});
// /events/active
