import { post, get, put } from 'components/_Services/HttpService';
import { ForgotPasswordModel, LoginModel, UserModel } from '../types/user.model';
import http from './http-common';
// import { baseURL } from './http-common';
const BASE_URL = process.env.REACT_APP_AUTHENTICATION_API_URL;
// const BASE_URL = 'https://6ags7buu6hmrnp3sb77zs6edae0zvxgd.lambda-url.eu-west-2.on.aws';

export const addUser = async (data: UserModel) =>
  http
    .post<UserModel>('/signup/email/otp', data)
    .then((response: any) => ({
      ...response.data,
      statusCode: response.status,
    }))
    .catch((e: any) => ({
      message: e.response?.data.error || e.message,
      errors: e.response?.data?.errorCode,
      error: true,
      statusCode: e.response.status,
      status: e.response?.data.status,
    }));

export const signupVerification = async (data: any) =>
  http
    .post<{ token: string }>('/signup/email/register', data)
    .then(
      (response: any) => ({
        ...response.data,
        statusCode: response.status,
      }),
      // return response.data;
    )
    .catch((e: any) => ({
      message: e.response?.data.error || e.message,
      error: true,
      errors: e.response?.data?.errorCode,
      statusCode: e.response.status,
      status: e.response?.data.status,
    }));

export const loginUser = async (data: LoginModel) =>
  http
    .post<LoginModel>('/signin/email', data)
    .then((response: any) => ({
      ...response,
      statusCode: response.status,
      success: true,
    }))
    .catch((e: any) => {
      console.log(e);
      return {
        message: e.response?.data.error || e.message,
        error: true,
        errors: e.response?.data?.errorCode,
        statusCode: e.response.status,
        status: e.response?.data.status,
      };
    });

export const forgotUserPassword = async (data: ForgotPasswordModel) =>
  http
    .post<ForgotPasswordModel>('/user/password/forgot', data)
    .then((response: any) => ({
      ...response.data,
      statusCode: response.status,
      success: true,
    }))
    .catch((e: any) => {
      console.log(e);
      return {
        message: e.response?.data.error || e.message,
        error: true,
        errors: e.response?.data?.errorCode,
        statusCode: e.response.status,
        status: e.response?.data.status,
      };
    });

export const verifyEmail = async (data: any) =>
  http
    .post<{ token: string }>('/user/password/forgot/reset', data)
    .then(
      (response: any) => ({
        ...response.data,
        statusCode: response.status,
        success: true,
      }),
      // return response.data;
    )
    .catch((e: any) => ({
      message: e.response?.data.error || e.message,
      error: true,
      errors: e.response?.data?.errorCode,
      statusCode: e.response.status,
      status: e.response?.data.status,
    }));

export const resendVerificationEmail = async (data: ForgotPasswordModel) =>
  http
    .post<{ token: string }>('/signup/email/otp/resend', data)
    .then(
      (response: any) => ({
        ...response.data,
        statusCode: response.status,
      }),
      // return response.data;
    )
    .catch((e: any) => ({
      message: e.response?.data.error || e.message,
      error: true,
      errors: e.response?.data?.errorCode,
      statusCode: e.response.status,
      status: e.response?.data.status,
    }));

export const requestPassword = async (data: any) =>
  http
    .post<{ token: string }>('/forgot-password', data)
    .then(
      (response: any) => ({
        ...response.data.data,
        statusCode: response.status,
      }),
      // return response.data;
    )
    .catch((e: any) => ({
      message: e.response?.data.message || e.message,
      errors: e.response?.data?.errorCode,
      statusCode: e.response.status,
      status: e.response?.data.status,
    }));
export const forgotPasswordUpdate = async (data: any) =>
  http
    .post<{ token: string }>('/forgot-password-update', data)
    .then(
      (response: any) => ({
        ...response.data,
        statusCode: response.status,
      }),
      // return response.data;
    )
    .catch((e: any) => ({
      message: e.response?.data.message || e.message,
      errors: e.response?.data?.errorCode,
      statusCode: e.response.status,
      status: e.response?.data.status,
    }));

export const setPassword = async (data: any) =>
  http
    .post<{ token: string }>('/setPassword', data)
    .then(
      (response: any) => ({
        ...response.data,
        statusCode: response.status,
      }),
      // return response.data;
    )
    .catch((e: any) => ({
      message: e.response?.data.message || e.message,
      errors: e.response?.data?.errorCode,
      statusCode: e.response.status,
      status: e.response?.data.status,
    }));
export const resendOTP = async (data: any) =>
  http
    .post<{ user_email: string }>('/forgot-password-otp', data)
    .then(
      (response: any) => ({
        ...response.data,
        statusCode: response.status,
      }),
      // return response.data;
    )
    .catch((e: any) => ({
      message: e.response?.data.message || e.message,
      errors: e.response?.data?.errorCode,
      statusCode: e.response.status,
      status: e.response?.data.status,
    }));

export const getuserProfile = async () => {
  const response = await get(`${BASE_URL}/profile`);
  return response;
};
// export const getuserProfile = async () => {
//   const token = localStorage.getItem('access_token');
//   const user_id = localStorage.getItem('user_id');
//   const headers = {
//     'Content-Type': 'application/json',
//     Authorization: `Bearer ${token}`,
//   };

//   return http
//     .get(`/profile/${user_id}`, { headers })
//     .then(
//       (response: any) => ({
//         ...response.data,
//         statusCode: response.status,
//       }),
//       // return response.data;
//     )
//     .catch((e: any) => ({
//       message: e.response?.data.message || e.message,
//       errors: e.response?.data?.errorCode,
//       statusCode: e.response.status,
//       status: e.response?.data.status,
//     }));
// };

export const changePassword = async (data: any) => {
  const response = await post(`${BASE_URL}/user/password/change`, data);
  if (response.error) {
    response.message = response.error;
    response.error = true;
    return response;
  }
  return response;
};
// export const changePassword = async (data: any) => {
//   const token = localStorage.getItem('access_token');
//   const headers = {
//     'Content-Type': 'application/json',
//     Authorization: `Bearer ${token}`,
//   };

//   return http
//     .post('/user/password/change', data, { headers })
//     .then(
//       (response: any) => ({
//         ...response.data,
//         success: true,
//         statusCode: response.status,
//       }),
//       // return response.data;
//     )
//     .catch((e: any) => ({
//       message: e.response?.data.error || e.message,
//       error: true,
//       errors: e.response?.data?.errorCode,
//       statusCode: e.response.status,
//       status: e.response?.data.status,
//     }));
// };

export const getComplimentary = async (data: any) => {
  const token = localStorage.getItem('access_token');
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return http
    .put('/user', data, { headers })
    .then(
      (response: any) => ({
        ...response.data,
        statusCode: response.status,
      }),
      // return response.data;
    )
    .catch((e: any) => ({
      message: e.response?.data.message || e.message,
      errors: e.response?.data?.errorCode,
      statusCode: e.response.status,
      status: e.response?.data.status,
    }));
};

export const welcomepackage = async (data: any) => {
  const response = await post(`${BASE_URL}/welcomepackage`, data);
  if (response.error) {
    response.message = response.error;
    response.error = true;
    return response;
  }
  return response;

  // const token = localStorage.getItem('access_token');
  // const headers = {
  //   'Content-Type': 'application/json',
  //   Authorization: `Bearer ${token}`,
  // };

  // return http
  //   .post('/welcomepackage', data, { headers })
  //   .then(
  //     (response: any) => ({
  //       ...response.data,
  //       statusCode: response.status,
  //     }),
  //     // return response.data;
  //   )
  //   .catch((e: any) => ({
  //     message: e.response?.data.message || e.message,
  //     errors: e.response?.data?.errorCode,
  //     statusCode: e.response.status,
  //     status: e.response?.data.status,
  //   }));
};
