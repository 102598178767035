import React from 'react';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Header from 'components/Auth/Header';

function TermsAndConditions() {
  
  return (
    <>
      <Header />
        <div className="content_container about_content_container">
          <div className="about_container contact_container">
            <div className="contact_blur" />
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="about_header text-center terms_conditions_header">
                    <h2 className="text-gradient">Terms & Conditions</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 terms_conditions_wrapper">
                  <div className="membership_tabs_content terms_conditions_container">
                    <Tabs defaultActiveKey="club" id="uncontrolled-tab-example">
                        <Tab eventKey="club" title="CLUB">
                          <div className="row">
                            <div className="col-lg-12 terms_conditions_content">
                              <iframe src='https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/tc-club.pdf'></iframe>
                            </div>
                          </div>
                        </Tab>
                        <Tab eventKey="digital" title="DIGITAL">
                          <div className="row">
                            <div className="col-lg-12 terms_conditions_content">
                              <iframe src='https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/tc-digital.pdf'></iframe>
                            </div>
                          </div>
                        </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default TermsAndConditions;
