import { post, get, put, eventsget } from 'components/_Services/HttpService';
import http from './http-common';

const BASE_URL = process.env.REACT_APP_AUTHENTICATION_API_URL;
// const BASE_URL = 'https://6ags7buu6hmrnp3sb77zs6edae0zvxgd.lambda-url.eu-west-2.on.aws';

export const getEvents = async () => {
  const response = await get(`${BASE_URL}/events`);
  return response;
};
export const getEventsList = async () => {
  const response = await eventsget(`${BASE_URL}/events/list`);
  return response;
};
// export const getEvents = async () => {
//   const token = localStorage.getItem('access_token');
//   const headers = {
//     'Content-Type': 'application/json',
//     Authorization: `Bearer ${token}`,
//   };

//   return http
//     .get('/events', { headers })
//     .then(
//       (response: any) => ({
//         ...response,
//         statusCode: response.status,
//       }),
//       // return response.data;
//     )
//     .catch((e: any) => ({
//       message: e.response?.data.message || e.message,
//       errors: e.response?.data?.errorCode,
//       statusCode: e.response.status,
//       status: e.response?.data.status,
//     }));
// };
export const getEventsData = async (user_id) => {
  const token = localStorage.getItem('access_token');
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return http
    .get(`/events/${user_id}`, { headers })
    .then(
      (response: any) => ({
        ...response.data,
        statusCode: response.status,
      }),
      // return response.data;
    )
    .catch((e: any) => ({
      message: e.response?.data.message || e.message,
      errors: e.response?.data?.errorCode,
      statusCode: e.response.status,
      status: e.response?.data.status,
    }));
};

export const getEventsSavedData = async () => {
  const response = await get(`${BASE_URL}/savedevents`);
  return response;
};
// export const getEventsSavedData = async (user_id) => {
//   const token = localStorage.getItem('access_token');
//   const headers = {
//     'Content-Type': 'application/json',
//     Authorization: `Bearer ${token}`,
//   };

//   return http
//     .get(`/events/userWiseEventsSavedData/${user_id}`, { headers })
//     .then(
//       (response: any) => ({
//         ...response.data,
//         statusCode: response.status,
//       }),
//       // return response.data;
//     )
//     .catch((e: any) => ({
//       message: e.response?.data.message || e.message,
//       errors: e.response?.data?.errorCode,
//       statusCode: e.response.status,
//       status: e.response?.data.status,
//     }));
// };

export const getEventsDetails = async (id: any) => {
  const response = await get(`${BASE_URL}/event/${id}`);
  return response.body;
};
