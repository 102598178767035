import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import LeftSidebar from 'components/App/LeftSidebar';

function AppLayout() {
  return (
    <div className='wrapper'>
      <LeftSidebar />
      <Outlet />
    </div>
  );
}
export default AppLayout;
