import React, { useEffect, useState } from 'react';
import './Login.css';
import { Link, useNavigate } from 'react-router-dom';
import { EyeHideIconSvg, EyeIconSvg } from '../App/SvgIcons';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setIsAuthenticatedData, setUserData } from 'slices/login';
import LocalStorageService from 'components/_Services/LocalStorageService';
import { loginUser, resendVerificationEmail } from '../../services/user';
import { AlertMessage } from '../common/Messages';
import MyCarousel from './Landingcardcarousel';

enum UserStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

// const actiationLink = () => <button onClik={reSendActivationCode}>test</button>;
// const enum LoginFormActivationdSteps {
//   LOGIN = 'LOGIN',
// }

function Login({
  doStep,
  setEmail,
  email,
  setToken,
}: {
  doStep: Function;
  setEmail: Function;
  email: string;
  setToken: Function;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState('');
  const [access_token, setAccessToken] = useState('');
  const [loadSubmit, setLoadSubmit] = useState(false);
  const [status, setStatus] = useState<UserStatus | null>(null);
  const [passwordType, setPasswordType] = useState('password');
  const [deviceIsMobile, setDeviceIsMobile] = useState(false);
  // const [resend, setResend] = useState(false);

  type Inputs = {
    user_email: string;
    password: string;
    email: string;
  };

  const showPassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({});
  const onSubmit: SubmitHandler<Inputs> = async (formdata) => {
    // console.log(formdata);
    setLoadSubmit(true);
    const userData = { email: formdata.user_email, password: formdata.password };

    // const {
    //   status,
    //   message,
    //   errors: err,
    //   errorCode,
    //   statusCode,
    //   access_token,
    //   data,
    //   email,
    // } = await loginUser(userData);
    const response = await loginUser(userData);
    setLoadSubmit(false);

    // setResend(err);

    setAccessToken(access_token);

    if (!response.error) {
      dispatch(setUserData(response));
      dispatch(setIsAuthenticatedData(true));
      // localStorage.setItem('access_token', response.token);
      // localStorage.setItem('userId', response.id);
      // localStorage.setItem('firstName', response.firstName);
      // localStorage.setItem('lastName', response.lastName);
      // localStorage.setItem('package', response.package);
      // localStorage.setItem('email', response.email);
      // console.log(response.data.accessTokenModel);
      // console.log(response.data.accessTokenModel.id);
      const complimentary = response.data.disableComplimentary;
      let responseModel = response.data.accessTokenModel;
      const updatedObject = {
        ...responseModel,
        disableComplimentary: response.data.disableComplimentary,
      };
      localStorage.setItem('userInfo', JSON.stringify(updatedObject));

      if (updatedObject.package === '') navigate('/app/membership');
      else navigate('/app/home');
      reset();
    } else {
      setMessage(response.message);
      setStatus(UserStatus.ERROR);
      setEmail(userData.email);
      console.log(status);
      console.log(message);
      // setResend(true);
    }
  };

  // const reSendActivationCode = async () => {
  //   const resendData = { email };
  //   const {
  //     status,
  //     message,
  //     errors: err,
  //     token,
  //     access_token,
  //   } = await resendVerificationEmail(resendData);

  //   setLoadSubmit(false);
  //   setStatus(status);
  //   setToken(token);
  //   if (status === UserStatus.SUCCESS) {
  //     doStep(LoginFormActivationdSteps.ENTER_OTP);
  //   } else {
  //     setMessage(message);
  //   }
  // };

  const checkLogin = async () => {
    if ((await LocalStorageService.getItem('userInfo')) === '') return;
    const userinfo = JSON.parse(await LocalStorageService.getItem('userInfo'));
    const currenturl = await LocalStorageService.getItem('current_url');
    if (userinfo !== undefined && userinfo.token) {
      if (userinfo.package !== '') {
        navigate('/app/home');
      } else {
        navigate('/app/membershipplans');
      }
    }
  };
  const checkIsMobile = () =>{
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)) {
      setDeviceIsMobile(true);
    } else {
      setDeviceIsMobile(false);
    }
  }

  useEffect(() => {
    checkLogin();
    checkIsMobile();
  }, []);
  return (
    <div className="signupformview">
      <div className="login_ripple_effect" />
      <div className="login_blur" />
      {/* <div className='login_ripple'>
        <img src="/images/login-ripple-bg.png" alt='' />
      </div> */}
      <div className="signup_left_container login_left_wrapper">
        <div className="logo text-center">
          <Link to="/">
            <img
              src="https://static-web.fra1.digitaloceanspaces.com/royalarion/ra-logo.svg"
              width="350"
              height="74"
              alt=""
            />
          </Link>
        </div>
        {status === UserStatus.SUCCESS && (
          <div className="alert alert-success" role="alert">
            <img
              className="success_fill_icon"
              src="/images/success-fill-icon.svg"
              width={24}
              height={24}
              alt=""
            />
            <p>{message}</p>
          </div>
        )}
        {/* {status === UserStatus.ERROR && (
          <div className="alert alert-danger" role="alert">
            <img
              className="error_fill_icon"
              src="/images/error-fill-icon.svg"
              width={24}
              height={24}
              alt=""
            />
            <p>{message}</p>
            {resend && <button onClick={reSendActivationCode}>CONTINUE</button>}
          </div>
        )} */}
        <h3 className="text-gradient mb-0">Log in</h3>
        {status === UserStatus.SUCCESS && <AlertMessage type="success" message={message} />}
        {status === UserStatus.ERROR && <AlertMessage type="danger" message={message} />}
        <h6>Welcome back! Please enter your details.</h6>
        <div className="form_container full_width">
          <form
            className="get-a-quote login-form"
            id="login-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="login_content full_width">
              <div className="form-group">
                <label>Email *</label>
                <input
                  type="text"
                  autoComplete="off"
                  id="user_email"
                  name="user_email"
                  placeholder="Email"
                  {...register('user_email', {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  })}
                  className={`form-control ${errors.user_email ? 'input_error' : ''}`}
                />
                {errors.user_email?.type === 'required' && (
                  <span className="error-msg">Email is required</span>
                )}
                {errors.user_email?.type === 'pattern' && (
                  <span className="error-msg"> Invalid Email format</span>
                )}
              </div>
              <div className="form-group">
                <label>Password *</label>
                <input
                  type={passwordType}
                  autoComplete="off"
                  id="password"
                  name="password"
                  placeholder="Password"
                  {...register('password', {
                    required: true,
                    maxLength: 30,
                    minLength: 1,
                    pattern: /^[a-zA-Z0-9_]+.*$/,
                  })}
                  className={`form-control ${errors.password ? 'input_error' : ''}`}
                />
                <div className="passwodicon" color="light" onClick={() => showPassword()}>
                  {passwordType === 'password' && <EyeHideIconSvg />}
                  {passwordType === 'text' && <EyeIconSvg />}
                </div>
                {errors.password?.type === 'required' && (
                  <span className="error-msg">Password is required</span>
                )}
                {errors.password?.type === 'maxLength' && (
                  <span className="error-msg"> Max length exceeded</span>
                )}
                {errors.password?.type === 'pattern' && (
                  <span className="error-msg">Invalid password format</span>
                )}
              </div>
              <div className="remember-wrap full_width pt-2">
                {/* <label>
                  <input type="checkbox" />
                  <span>Remember</span>
                </label> */}
                <Link to="/forgotpassword">Forgot password</Link>
              </div>
              <div className="buttons-wrap full_width">
                <button type="submit" disabled={loadSubmit}>
                  {loadSubmit && <span className="spinner-border spinner-border-sm" />}
                  &nbsp;&nbsp; Sign in
                </button>
              </div>
              <div className="social-buttons-wrap full_width hide_block">
                <button type="button">
                  <img
                    src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/apple-icon.svg"
                    width="24"
                    height="24"
                    alt=""
                  />
                  Sign up with Apple
                </button>
                <button type="button">
                  <img
                    src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/google-icon.svg"
                    width="24"
                    height="24"
                    alt=""
                  />
                  Sign up with Google
                </button>
              </div>
              <div className="allready_account full_width text-center pt-4">
                <p>
                  Don't have an account?
                  <Link to="/signup">Sign up</Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
      {!deviceIsMobile &&
      <div className="signup_right_container">
        <MyCarousel />
      </div>
      }
    </div>
  );
}

export default Login;
