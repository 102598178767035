import React, { useEffect, useState } from 'react';
import './Login.css';
import { Link } from 'react-router-dom';

function ResetPasswordSuccess({ doStep }: { doStep: Function }) {
  return (
    <section className="signup_container">
      <div className="full_width">
        <div className="password_ripple_effect" />
        <div className="password_blur" />
        <div className="success_message_wrapper">
          <div className="success_message_container">
            <div className="img_container">
              <img
                src="https://static-web.fra1.digitaloceanspaces.com/royalarion/success-banner-img.jpg"
                alt=""
                width={600}
                height={384}
              />
            </div>
            <div className="success_content">
              <h5>Your Password has been updated successful!</h5>
              <p>Welcome to The Royal Arion Club</p>
              <div className="home_link">
                <Link to="/login">Go to Login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    // <div className="full_width">
    //   <div className="password_ripple">
    //     <img src="/images/forgot-password-ripple-bg.png" alt="" />
    //   </div>
    //   <div className="password_blur">
    //     <div className="middle_container">
    //       <div className="form_container password_form_container">
    //         <form id="contact-form1" className="get-a-quote" autoComplete="off">
    //           <div className="text-center">
    //             <div className="heading_content">
    //               <img src="/images/email-verify-icon.svg" alt="" width="56" height="56" />
    //               <h2 className="text-gradient">Email verified</h2>
    //               <p className="p-0"></p>
    //             </div>
    //           </div>
    //           <div className="back_login_link full_width">
    //             <Link to="/login">
    //               <img src="/images/arrow-left-icon.svg" alt="" width="20" height="20" />
    //               Back to Login
    //             </Link>
    //           </div>
    //         </form>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    // <div className="middle_container">
    //   <div className="logo logo-center text-center">
    //     <Link to="/">
    //       <img
    //         src="https://static-web.fra1.digitaloceanspaces.com/royalarion/ra-logo.svg"
    //         width="281"
    //         height="59"
    //         alt=""
    //       />
    //     </Link>
    //   </div>
    //   <div className="form_container password_form_container">
    //     <form id="contact-form1" className="get-a-quote" autoComplete="off">
    //       <div className="text-center">
    //         <div className="heading_content">
    //           <img src="/images/password-icon.svg" alt="" width="56" height="56" />
    //           <h2>Forgot Password</h2>
    //           <p className="p-0">No worries, we'll send you reset instructions.</p>
    //         </div>
    //       </div>
    //       <div className="login_content">
    //         <div className="form-group">
    //           <label>Email *</label>
    //           <input className="form-control" type="text" placeholder="Enter your email" />
    //         </div>
    //         <div className="buttons-wrap full_width mt-2">
    //           <button type="submit">Reset Password</button>
    //         </div>
    //       </div>
    //       <div className="back_login_link full_width">
    //         <Link to="/login">
    //           <img src="/images/arrow-left-icon.svg" alt="" width="20" height="20" />
    //           Back to Login
    //         </Link>
    //       </div>
    //     </form>
    //   </div>
    // </div>
  );
}

export default ResetPasswordSuccess;
