import React, { useEffect, useState } from 'react';
import { Link, useLocation, NavLink, useNavigate } from 'react-router-dom';

import {
  RacingSvg,
  PartnerSvg,
  AppSvg,
  CalenderSvg,
  ConciergeSvg,
  ProfileSvg,
  NotificationSvg,
  HelpSupportSvg,
  SignOutSvg,
} from './SvgIcons';

function LeftSidebar() {
  const navigate = useNavigate();
  const logout = async () => {
    localStorage.setItem('userInfo', '');
    setTimeout(() => {
      navigate('/');
    }, 100);
  };
  return (
    <div className="left_container">
      <div className="left_logo">
        <Link to="/app/home">
          <img
            src="https://static-web.fra1.cdn.digitaloceanspaces.com/royalarion/app-logo.svg"
            alt=""
            width="264"
            height="36"
          />
        </Link>
      </div>
      <div className="left_menu">
        <ul>
          <li>
            <NavLink to="/app/home">
              <AppSvg />
              <span>Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/racing">
              <RacingSvg />
              <span>Racing</span>
              <small />
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/events">
              <CalenderSvg />
              <span>Events</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/concierge">
              <ConciergeSvg />
              <span>Concierge</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/partners">
              <PartnerSvg />
              <span>Partners</span>
            </NavLink>
          </li>
        </ul>
        <div className="bottom_nav_wrapper d-none d-lg-block">
          <div className="bottom_nav_content full_width">
            <NavLink to="/app/profile">
              <ProfileSvg /> <span>My Profile</span>{' '}
            </NavLink>
            <NavLink to="/app/helpandsupport">
              <HelpSupportSvg /> <span>Help and Support</span>{' '}
            </NavLink>
            {/* <NavLink to="notifications">
              <NotificationSvg />
              <span>Notifications</span>{' '}
            </NavLink> */}
            <NavLink to="/" onClick={logout}>
              <SignOutSvg />
              <span>Sign Out</span>{' '}
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftSidebar;
