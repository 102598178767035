import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
// import { AlertMessage } from '../common/Messages';
import { getVenuesData, getSisStreamData, addSisStreamData } from '../../../services/racing';
import { venueRefactorDataFn } from '../Racing/racingUtils';
import { AlertMessage } from 'components/common/Messages';
// import { logout } from '../Auth/logout';

enum UserStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

function UpdateStream() {
  type Inputs = {
    provider: string;
    countryCode: string;
    venueCode: string;
    programId: string;
    streamId: string;
    iframeFlag: string;
    isPaid: string;
  };
  const [venuesList, setVenuesList] = useState([]);
  const [sisData, setSisData] = useState([]);
  const [loadSubmit, setLoadSubmit] = useState(false);
  const [status, setStatus] = useState<UserStatus | null>(null);
  const [message, setMessage] = useState('');
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<Inputs>({});

  const getVenues = async () => {
    const data = await getVenuesData();
    console.log(data);
    const venueRefactorData = venueRefactorDataFn(data);
    console.log(venueRefactorData);
    setVenuesList(venueRefactorData);
  };
  const getsisdata = async () => {
    const sisstreamData = await getSisStreamData();
    setSisData(sisstreamData);
  };
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    //setLoadSubmit(true);
    console.log(data);
    const filteredData = venuesList.filter((item: any) => item.venueId === data.venueCode);
    console.log(filteredData);

    // const venueData = Object.entries(venuesList).filter((venue, key) => {
    //   debugger;
    //   const [venueId] = venue[1].venueId;

    //   return venueId === data.venueCode;
    // });
    //
    const streamData = {
      provider: 'SIS',
      countryCode: 'UK',
      venueCode: filteredData[0].venueCode,
      programId: filteredData[0].venueId,
      streamId: data.streamId,
      iframeFlag: 'Y',
      isPaid: 'Y',
    };
    // console.log(streamData);
    //data.newpassword = data.password;
    const response = await addSisStreamData(streamData);
    console.log(response);
    if (response.error) {
      setLoadSubmit(false);
      setStatus(UserStatus.ERROR);
      setMessage(response.message);
    } else {
      setMessage('Stream Added successfully.');
      setLoadSubmit(false);
      setStatus(UserStatus.SUCCESS);
      reset();
    }
  };
  useEffect(() => {
    getVenues();
    getsisdata();
  }, []);
  return (
    <div className="right_container profile_container">
      <div className="right_content">
        <div className="heading">
          <div className="back_home">
            <Link to="/app/profile/personaldetails">
              <img src="/images/arrow-left-line-icon.svg" width="24" height="24" alt="" />
            </Link>
          </div>
          <h1>Personal Details</h1>
        </div>
        {status === UserStatus.SUCCESS && <AlertMessage type="success" message={message} />}
        {status === UserStatus.ERROR && <AlertMessage type="danger" message={message} />}

        <div className="profile_wrapper full_width">
          <div className="profile_content full_width">
            <div className="form_container">
              <div className="profile_heading full_width">
                <h5>Update Stream</h5>
                <p>You can update the stream daily here</p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-field select-field full_width">
                  <label className="full_width">Venue Code</label>
                  <select
                    id="venueCode"
                    name="venueCode"
                    {...register('venueCode')}
                    className={`select ${errors.venueCode ? 'input_error' : ''}`}
                  >
                    <option value="">Select Venue</option>
                    {venuesList.map((venue) => (
                      <option value={venue.venueId}>{venue.venueName}</option>
                    ))}
                  </select>
                </div>

                <div className="form-field select-field full_width ">
                  <label className="full_width">stream Id</label>
                  <select
                    id="streamId"
                    name="streamId"
                    {...register('streamId')}
                    className={`select ${errors.streamId ? 'input_error' : ''}`}
                  >
                    <option value="">Select Stream</option>
                    {sisData.map((sis) => (
                      <option value={sis.streamId}>{sis.title}</option>
                    ))}
                  </select>
                </div>

                <div className="form-field select-field full_width d-none">
                  <label className="full_width">Iframe Flag</label>
                  <select>
                    <option>Y</option>
                    <option>N</option>
                  </select>
                </div>
                <div className="form-field select-field full_width d-none">
                  <label className="full_width">Is Paid</label>
                  <select>
                    <option>Y</option>
                    <option>N</option>
                  </select>
                </div>
                <div className="btn-field full_width">
                  <button type="submit" disabled={loadSubmit}>
                    {loadSubmit && <span className="spinner-border spinner-border-sm"></span>}
                    &nbsp;&nbsp; Update Stream
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateStream;
